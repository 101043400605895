import React from 'react';
import Style from './index.module.scss';

const CounterCard = ({ bgColor, color, icon, count, title }) => {
  return (
    <div className={Style.container} style={{backgroundColor:bgColor, color:color}}>
      <div className={Style.icon}>
          {icon}
      </div>
      
     <div>
     <h3>{count}</h3>
      <p>{title}</p>
     </div>
    </div>
  )
}

export default CounterCard;
