/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useAuth } from '../../../contexts/AuthContext';
import Style from './index.module.scss';
import { CustomButton, Loader, Modal, SectionCard } from '../../../components'
import { makeApiCall } from '../../../utils/makeApiCall';
import { Avatar, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import { wallOptions, roofOptions, floorOptions } from '../../../data'


const ManagePosts = ({ portfolio, onSubmitPostHandle, closeModal }) => {

    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState({});

    const fetchData = async () => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: '/posts/individual'
            })
            const filtered = res?.data?.length > 0 && res.data.map(item => ({ ...item, isChecked: portfolio?.posts?.some(({ id: idB }) => item.id === idB) }));
            setPosts(filtered);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        setLoading(true)
        fetchData();
    }, []);

    const onPostSelected = (id) => {
        const item = posts.find((item) => item.id === id);
        const newPosts = [...posts];
        newPosts.find(item => item.id === id).isChecked = !item.isChecked
        setPosts(newPosts);
    };

    const handleSubmit = async (e) => {
        const filteredPosts = posts.filter(a => a.isChecked);
        onSubmitPostHandle(filteredPosts)
        closeModal();
    };

    return (
        <>
            {loading ? <div className="loader_container"><Loader /></div> :
                <div>
                    <div className={Style.post_card_container}>
                        {posts?.length > 0 &&
                            <div className={Style.post_card_container}>
                                {posts.map(post =>
                                    <div className={`${Style.post_card} ${post.isChecked ? Style.selected : ""}`} onClick={() => onPostSelected(post.id)}>
                                        <img src={post.image} alt={post?.name} />
                                        <h5>{post?.name}</h5>

                                        {post.isChecked && <div className={Style.post_card_overlay}> <CheckCircleIcon /></div>}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="pull_right_container mt_3">
                        <CustomButton text="Add to Portfolio" onClick={handleSubmit} variant="contained" color="success" startIcon={<SaveIcon />} />
                    </div>
                </div>
            }
        </>
    )
};

export default () => {
    const params = useParams();
    const { currentUser } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [portfolio, setPortfolio] = useState({});
    const [editSponsorData, setEditSponsorData] = useState(null);
    const [editPostData, setEditPostData] = useState({});
    const [managePostDialog, setManagePostDialog] = useState(false);


    const getPosts = async () => {
        try {
            setLoading(true);
            const res = await makeApiCall({
                method: "GET",
                path: `/rooms?artistId=${currentUser?.id}&portfolio=true`
            })
            if (res?.data) {
                setPortfolio(res.data[0]);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getPosts();
    }, []);

    const onRoomNameChange = (value, id) => {
        const newRooms = [...portfolio]
        const filteredRoom = newRooms.filter(nr => nr.id === id);
        filteredRoom[0].name = value;
        setPortfolio(newRooms)
    };

    const toggleManagePostDialog = () => {
        setManagePostDialog(!managePostDialog)
    };

    const onSubmitPostHandle = (posts) => {
        const newPortfolio = { ...portfolio };
        newPortfolio.posts = posts;
        setPortfolio(newPortfolio);
    };

    const updateRoom = async () => {
        try {
            setLoading(true);
            const res = await makeApiCall({
                method: "PUT",
                path: `/rooms/${portfolio.id}`,
                payload: { posts: portfolio.posts.map(r => r.id), name:"My Portfolio" }
            })
            getPosts()
            setLoading(false);
        } catch (err) {
            setLoading(true);
            console.error(err);
        }
    };

    const createRoom = async ({ value }) => {
        setLoading(true);
        try {
            const res = await makeApiCall({
                method: "POST",
                path: `/rooms`,
                payload: {
                    name: "My Portfolio",
                    portfolio: true
                }
            })
            getPosts();
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <SectionCard title="My Portfolio" defaultPadding addButtonText="Update" onAddButtonClick={updateRoom}>
                {loading ?
                    <div className="loader_container"><Loader /></div>
                    :
                    (portfolio?.id ? <div className={Style.room_container} key={portfolio?.id}>
                        {/* <div className={Style.room_title}><input value={portfolio?.name} onChange={(e) => onRoomNameChange(e.target.value, room?.id)} /></div> */}
                        <div className={Style.wall_container}>
                            <SectionCard>
                                <div className={Style.body_container}>
                                    {portfolio?.posts?.length === 0 ? 'You have not added any Art yet' : null}
                                    <div className={Style.post_card_container}>
                                        {portfolio?.posts?.length > 0 && portfolio.posts.map(post =>
                                            <div className={Style.post_card}>
                                                <img src={post?.image} alt={post?.name} />
                                                <h5>{post?.name}</h5>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pull_right_container mt_3">
                                    <CustomButton fullWidth variant="outlined" text="Manage Arts" onClick={toggleManagePostDialog} />
                                </div>
                            </SectionCard>
                        </div>
                    </div> :
                        <CustomButton text="Create Portfolio Room" onClick={createRoom} />

                    )
                }

                <Modal title={`Manage My Posts`} open={managePostDialog} onClose={toggleManagePostDialog}>
                    <ManagePosts portfolio={portfolio} onSubmitPostHandle={onSubmitPostHandle} closeModal={toggleManagePostDialog} />
                </Modal>

            </SectionCard>
        </>
    )
};