/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Style from './index.module.scss'
import FormInput from '../../../components/FormInput';
import { CustomButton, SectionCard } from '../../../components';
import { Avatar } from '@mui/material';
import { useSnackbar } from 'notistack';


export default () => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { currentUser, updatePassword, updateUserInfo } = useAuth();

  const [personalInfo, setPersonalInfo] = useState({
    name: currentUser.name || "",
    avatar: currentUser.avatar || "",
    mobile: currentUser.mobile || ""
  });

  const [pass, setPass] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const [personalLoader, setPersonalLoader] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);


  const personalInfoInputs = [
    {
      label: "Name",
      name: "name",
      errormessage:
        "Name should be 3-16 characters and shouldn't include any special character!",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true
    },
    {
      label: "Profile Image",
      name: "avatar"
    },
    {
      label: "Contact No",
      name: "mobile"
    },
    {
      label: "Logo",
      name: "logo"
    },
    {
      label: "Industry",
      name: "industry"
    },
    {
      label: "Address",
      name: "address"
    },
    {
      label: "Country",
      name: "country"
    },
    {
      label: "State",
      name: "state"
    },
    {
      label: "PIN",
      name: "pin"
    }
  ];

  const passwordInputs = [
    {
      label: "Current Password",
      name: "currentPassword",
      type: "password",
      required: true
    },
    {
      label: "New Password",
      name: "newPassword",
      type: "password",
      required: true
    },
    {
      label: "Confirm new Password",
      name: "confirmPassword",
      type: "password",
      required: true
    }
  ];

  const onPersonalInfoChange = (e) => {
    setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  };

  const onPersonalInfoSubmit = async (e) => {
    e.preventDefault();
    setPersonalLoader(true);
    await updateUserInfo(personalInfo);
    setPersonalLoader(false)
  };

  const onPasswordChange = (e) => {
    setPass({ ...pass, [e.target.name]: e.target.value });
  };

  const onPasswordSubmit = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = pass;
    if (newPassword !== confirmPassword) {
      enqueueSnackbar('Password and confirm password should be same', { variant: 'error' });
      return;
    }
    setPasswordLoader(true)
    await updatePassword({
      currentPassword,
      newPassword
    });
    setPasswordLoader(false);
  };


  return (
    <div className="group_container gap_2">
      <div className={Style.left}>

        <SectionCard defaultPadding>
          <div className={Style.profile_pic_container}>
            <div className={Style.profile_pic}>
              <Avatar variant="rounded" alt="Avatar" src={currentUser?.avatar} style={{ width: '300px', height: '300px', fontSize: '5em' }}>
                {currentUser?.name.charAt(0)}
              </Avatar>
            </div>
          </div>
        </SectionCard>
        <SectionCard title="Change Password" defaultPadding>
          <form onSubmit={onPasswordSubmit}>
            {passwordInputs.map((input) => (
              <FormInput {...input} key={input.name} value={pass[input.name]} onChange={onPasswordChange} fullWidth disabled={passwordLoader} />
            ))}
            <div className="form_submit_button">
              <CustomButton size="small" text="Update" type="submit" loading={passwordLoader} disabled={passwordLoader} />
            </div>
          </form>
        </SectionCard>
      </div>
      <div className={Style.right}>
        <SectionCard title="Basic Info" defaultPadding>
          <form onSubmit={onPersonalInfoSubmit}>
            {personalInfoInputs.map((input) => (
              <FormInput {...input} key={input.name} value={personalInfo[input.name]} onChange={onPersonalInfoChange} fullWidth disabled={personalLoader} />
            ))}
            <div className="form_submit_button">
              <CustomButton size="small" text="Update" type="submit" loading={personalLoader} disabled={personalLoader} />
            </div>
          </form>
        </SectionCard>
      </div>
    </div>
  )
};