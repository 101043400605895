import { Login, Register, ForgotPassword, ResetPassword } from "./pages";

import { Dashboard as SuperAdminDashboard, Profile as SuperAdminProfile, Admin as SuperAdminAdmin } from "./modules/superAdmin";
import { Dashboard as AdminDashboard, Profile as AdminProfile, Category as AdminCategory, Organiser as AdminOrganiser, Messenger as AdminMessenger, Contacts, Courses } from "./modules/admin";
import { Dashboard as OrganiserDashboard, Profile as OrganiserProfile, Fair as OrganiserFair, Rooms as OrganiserRooms, Messenger as OrganiserMessenger, Competition, Exhibition } from "./modules/organiser";
import { Dashboard as ParticipantDashboard, Profile as ParticipantProfile, Content as ParticipantContent, Messenger as ArtistMessenger, Rooms as ParticipantRooms, Portfolio, Gallery } from "./modules/artist";
import { Dashboard as SponsorDashboard, Profile as SponsorProfile } from "./modules/sponsor";

import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PaletteIcon from '@mui/icons-material/Palette';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ChatIcon from '@mui/icons-material/Chat';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CategoryIcon from '@mui/icons-material/Category';

export const publicRoutes = [
  { id: 101, path: "/", name: "Login", Component: <Login /> },
  { id: 1011, path: "/login", name: "Login", Component: <Login /> },
  { id: 102, path: "/register", name: "Register", Component: <Register /> },
  { id: 103, path: "/forgot-password", name: "Forgot Password", Component: <ForgotPassword /> },
  { id: 103, path: "/reset-password/:token", name: "Reset Password", Component: <ResetPassword /> }
];


export const privateRoutes = [
  { id: "sadmn1", path: "/dashboard", name: "Dashboard", Component: <SuperAdminDashboard />, icon: DashboardIcon, access: 'super_admin' },
  { id: "sadmn2", path: "/profile", name: "Profile", Component: <SuperAdminProfile />, icon: ManageAccountsIcon, access: 'super_admin' },
  { id: "sadmn3", path: "/admin", name: "Admin", Component: <SuperAdminAdmin />, icon: GroupIcon, access: 'super_admin' },

  { id: "admn1", path: "/dashboard", name: "Dashboard", Component: <AdminDashboard />, icon: DashboardIcon, access: 'admin' },
  { id: "admn2", path: "/profile", name: "Profile", Component: <AdminProfile />, icon: ManageAccountsIcon, access: 'admin' },
  { id: "admn3", path: "/organiser", name: "Organiser", Component: <AdminOrganiser />, icon: GroupIcon, access: 'admin' },
  { id: "admn4", path: "/category", name: "Category ", Component: <AdminCategory />, icon: CategoryIcon, access: 'admin' },
  { id: "admn5", path: "/contacts", name: "Contacts ", Component: <Contacts />, icon: AccountBoxIcon, access: 'admin' },
  { id: "admn6", path: "/courses", name: "Courses ", Component: <Courses />, icon: PaletteIcon, access: 'admin' },
  { id: "admn7", path: "/chat", name: "Chat", Component: <AdminMessenger />, icon: ChatIcon, access: 'admin' },

  { id: "org1", path: "/dashboard", name: "Dashboard", Component: <OrganiserDashboard />, icon: DashboardIcon, access: 'organiser' },
  { id: "org2", path: "/profile", name: "Profile", Component: <OrganiserProfile />, icon: ManageAccountsIcon, access: 'organiser' },
  { id: "org3", path: "/competition", name: "Competitions", Component: <Competition />, icon: ArticleIcon, access: 'organiser' },
  // { id: "org4", path: "/exhibition", name: "Exhibition", Component: <Exhibition />, icon: StorefrontIcon, access: 'organiser' },
  { id: "org5", path: "/fair", name: "Fair", Component: <OrganiserFair />, icon: StorefrontIcon, access: 'organiser' },
  { id: "org6", path: "/rooms", name: "Manage Rooms", Component: <OrganiserRooms />, icon: GroupIcon, access: 'organiser' },
  { id: "org7", path: "/chat", name: "Chat", Component: <OrganiserMessenger />, icon: ChatIcon, access: 'organiser' },

  { id: "art1", path: "/dashboard", name: "Dashboard", Component: <ParticipantDashboard />, icon: DashboardIcon, access: 'ARTIST' },
  { id: "art2", path: "/profile", name: "Profile", Component: <ParticipantProfile />, icon: ManageAccountsIcon, access: 'ARTIST' },
  { id: "art3", path: "/posts", name: "Posts", Component: <ParticipantContent />, icon: DynamicFeedIcon, access: 'ARTIST' },
  // { id: "org4", path: "/galleries", name: "Galleries", Component: <Gallery />, icon: StorefrontIcon, access: 'ARTIST' },
  { id: "art5", path: "/portfolio", name: "Portfolio", Component: <Portfolio />, icon: StorefrontIcon, access: 'ARTIST' },
  { id: "art6", path: "/rooms", name: "Rooms", Component: <ParticipantRooms />, icon: PaletteIcon, access: 'ARTIST' },
  { id: "art7", path: "/chat", name: "Chat", Component: <ArtistMessenger />, icon: ChatIcon, access: 'ARTIST' },

  { id: "sopn1", path: "/dashboard", name: "Dashboard", Component: <SponsorDashboard />, icon: DashboardIcon, access: 'sponsor' },
  { id: "spon2", path: "/profile", name: "Profile", Component: <SponsorProfile />, icon: ManageAccountsIcon, access: 'sponsor' },
];


export const filteredRoute = (role) => {
  const x = privateRoutes.filter(route => role === route.access)
  return x;
};