/* eslint-disable import/no-anonymous-default-export */
import { Messenger } from "../../../components";
import { useAuth } from '../../../contexts/AuthContext';
import Style from './index.module.scss';

export default () => {
    const { currentUser } = useAuth();

    return (
        <>
            <Messenger currentUser={currentUser} />
        </>
    );
}