import React, { Fragment } from "react";
import { useTable, useExpanded } from "react-table";
import Style from './index.module.scss'
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { TableLoader } from "../Loader";

const Table = ({ columns, data=[], loading = false, renderRowSubComponent = undefined, sortState = false, onSortIconClick = () => { }, noItemsToDisplayMessage = "", style = {}, hideHeadRow = false, hideColumns = [] }) => {

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, visibleColumns, state: { expanded }, } = useTable({ columns, data, initialState: {hiddenColumns: hideColumns} }, useExpanded);


    const getStyle = (horizontalAlign, width, hideColunm) => {
        let s = {};
        if (horizontalAlign) s.textAlign = horizontalAlign;
        if (width) s.width = width;
        if (hideColunm) s.display = 'none';
        return s;
    }

    return (
        <div className={Style.table_container}>
            <table className={Style.table} {...getTableProps()} style={style}>
                {data.length>0 && !hideHeadRow && <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`h-row-${i}`}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map((column, j) => (
                                    // Apply the header cell props
                                    <Fragment key={`h-col-${j}`}>
                                        {!column.noHeading ? <th {...column.getHeaderProps()} style={getStyle(column?.horizontalAlign, column?.rowWidth)} className={`${Style.col_container} ${column?.wrapAll ? 'wrap_all' : ''}`}>
                                            {// Render the header
                                                // column.isSortable ? (<>{column.render('Header')}
                                                //     <span onClick={onSortIconClick} style={{ marginLeft: "5px" }}>{sortState ? <FaCaretUp onClick={onSortIconClick} /> : <FaCaretDown />}</span>
                                                // </>) : column.render('Header')
                                                column.headerComponent ? column.headerComponent() : column.render('Header')
                                                }
                                        </th> : <th></th>}
                                    </Fragment>
                                ))}
                        </tr>
                    ))}
                </thead>}
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                    {
                        loading ? (<tr><td colSpan={visibleColumns.length}>
                            <TableLoader count={10}/>
                        </td></tr>) : noItemsToDisplayMessage && data.length == 0 ? (<tr><td style={{ fontWeight: "bold" }} colSpan={visibleColumns.length}>{noItemsToDisplayMessage}</td></tr>) : (
                            <Fragment>
                                {rows.map((row, i) => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <Fragment key={`row-${i}`}>
                                            <tr {...row.getRowProps()} >
                                                {// Loop over the rows cells
                                                    row.cells.map((cell, j) => {
                                                        // Apply the cell props
                                                        return (
                                                            <td data-title={cell.column.Header ? cell.column.Header : ""} {...cell.getCellProps()} key={`cell-${j}`} style={getStyle(cell?.column?.horizontalAlign,  cell?.column?.rowWidth )} className={`${Style.cell_container} ${cell?.column?.wrapAll ? 'wrap_all' : ''}`}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                            {row.isExpanded &&
                                                renderRowSubComponent({ row, rowProps: { ...row.getRowProps() }, visibleColumns })}
                                        </Fragment>
                                    )
                                })}
                            </Fragment>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

//It's important that we're using React.Memo here 
//to ensure that our data isn't recreated on every render. 
//If we didn't use React.Memo, the table would think it was receiving 
//new data on every render and attempt to recalulate a lot of logic every single time
const CustomTable = React.memo(Table);

export default CustomTable;