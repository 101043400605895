/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useEffect, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { SectionCard, CounterCard, CustomButton, UserCountCard, TableHeaderLoader, Greetings } from '../../../components';
import Style from './index.module.scss'
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import PageviewIcon from '@mui/icons-material/Pageview';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import { makeApiCall } from '../../../utils/makeApiCall';
import Chart from "react-apexcharts";


const data = {
  series: [
    {
      name: "Review",
      data: [10, 50, 30, 90, 40, 120, 100],
    },
  ],
  options: {
    chart: {
      type: "area",
      height: "auto",
    },

    fill: {
      colors: ["#fff"],
      type: "gradient",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#4B0082"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
    },
    yaxis: {
      show: false
    },
    toolbar: {
      show: false
    }
  },
};

export default () => {

  const { currentUser } = useAuth();
  const videoIdref = useRef();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState(null);

  const fetchData = async () => {
    try {
      const res = await makeApiCall({
        method: "GET",
        path: "/count"
      })

      setCounts(res);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
    setLoading(false)
  };

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, []);

  return (
    <div className="dashboard">
      <Greetings />

      {loading ? <SectionCard minHeight={100} defaultPadding><TableHeaderLoader count={3} height={100} /></SectionCard> :
        <div className="group_container gap_2 mb_2">
          <CounterCard count={counts?.fairs?.total} title="Total Fairs" bgColor="#2673e5" color="#fff" icon={<ScreenSearchDesktopIcon />} />
          <CounterCard count={counts?.agendas?.total} title="Total Agendas" bgColor="#dc3545" color="#fff" icon={<GroupIcon />} />
          <CounterCard count={counts?.posts?.total} title="Total Posts" bgColor="#e1aa04" color="#fff" icon={<ShoppingCartIcon />} />
          {/* <CounterCard count={768} title="Transactions" bgColor="#198754" color="#fff" icon={<AttachMoneyIcon />} /> */}
        </div>
      }

     

      {/* <SectionCard minHeight={300} >
        <Chart options={data.options} series={data.series} type="area" height="300" />
      </SectionCard> */}

     
      {loading ? <SectionCard minHeight={100} defaultPadding><TableHeaderLoader count={4} height={100} /></SectionCard> :
        <div className="group_container gap_2 mb_2">
          <UserCountCard count={counts?.admins?.total} title="Admin Accounts" icon={<PersonIcon />} />
          <UserCountCard count={counts?.organisers?.total} title="Organiser Accounts" icon={<PersonIcon />} />
          <UserCountCard count={counts?.participants?.total} title="Participant Accounts" icon={<PersonIcon />} />
          <UserCountCard count={counts?.sponsors?.total} title="Sponsor Accounts" icon={<PersonIcon />} />
        </div>
      }
      

      <SectionCard minHeight={300} defaultPadding>
        More Data will come
      </SectionCard>

    </div>
  )
};