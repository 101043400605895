/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Style from './index.module.scss';
import { CustomButton } from '..';
import AddIcon from '@mui/icons-material/Add';

export default ({ children, defaultPadding = false, minHeight = '', style = {}, title = '', maxHeight ='' , parent=false, addButtonText = "", onAddButtonClick = () => { }, loading = false}) => {

    const getStyle = () => {
        let s = {};
        s.padding = defaultPadding ? '15px' : '';
        if (minHeight) s.minHeight = minHeight;
        if (maxHeight) s.maxHeight = maxHeight;
        if (maxHeight) s.overflow = 'auto';
        if (parent) s.position = 'relative';

        return s;
    }


    return (
        <section className={Style.section_container} style={getStyle()}>
            {title || addButtonText ? <div className={Style.title} style={{ paddingLeft: defaultPadding ? '0' : '10px', paddingTop: defaultPadding ? '0' : '10px' }}>
                {title}

                {addButtonText && <CustomButton variant="contained" text={addButtonText} startIcon={<AddIcon />} onClick={onAddButtonClick} />}

            </div> : ''}

            
            {loading ? <p>Loading...</p>  : children}
        </section>
    )
};




// import React from 'react';
// import Style from './index.module.scss';

// const SectionCard = ({ children, title = "", minHeight = "150", addButtonText = "", noPadding = false, onAddButtonClick = () => { } }) => {
//   return (
//     <div className={Style.container} style={{ minHeight: `${minHeight}px`, padding: noPadding ? '' : '15px' }}>
//       {title && <div className={Style.card_header}>
//         <h3 className={Style.card_title}>{title}</h3>
//         {addButtonText && <CustomButton variant="contained" text={addButtonText} startIcon={<AddIcon />} onClick={onAddButtonClick} />}
//       </div>}
//       <div className={Style.card_body}>{children}</div>
//     </div>
//   )
// }

// export default SectionCard;
