/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, useMemo } from 'react'
import { Table, ImageCard, CustomButton, SectionCard, Modal, CustomIconButton, TableLoader, FormInput } from '../../../components'
import { Link } from 'react-router-dom';
import { makeApiCall } from '../../../utils/makeApiCall';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';


const OrganiserForm = ({ type, data, getNewList, closeModal }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        name: "",
        mobile: "",
        email: ""
    });

    const inputs = [
        {
            name: "name",
            label: "Name",
            errormessage:
                "Name should be 3-16 characters and shouldn't include any special character!",
            pattern: "^[A-Za-z0-9]{3,16}$",
            required: true,
        },
        {
            name: "email",
            label: "Email",
            required: true,
        },
        {
            name: "mobile",
            label: "Mobile",
            required: true
        }
    ];

    const onChangeHande = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await makeApiCall({
            method: "POST",
            path: "/auth/invite",
            payload: {
                ...values,
                role: "organiser"
            },
        })

        if (res.success) {
            enqueueSnackbar('Admin Added successfully', { variant: 'success' });
        } else {
            enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        }
        getNewList();
        closeModal();
        setLoading(false);
    };

    useEffect(() => {
        if (type === 'edit') {
            setValues({
                name: data.name,
                mobile: data.mobile,
                email: data.email
            })
        }
    }, []);


    return (
        <form onSubmit={handleSubmit}>
            {inputs.map((input) => (
                <FormInput
                    {...input}
                    key={input.name}
                    value={values[input.name]}
                    onChange={onChangeHande}
                    disabled={loading}
                    fullWidth
                />
            ))}

            <div className="form_submit_button">
                <CustomButton variant="contained" color="success" size="small" text={type === 'edit' ? 'Update' : 'Send Invite'} type="submit" loading={loading} disabled={loading} />
            </div>
        </form>
    )
};

export default () => {
    const [organisers, setOrganisers] = useState([])
    const [loading, setLoading] = useState(false);
    const [addOrganiserDialog, setAddOrganiserDialog] = useState(false);
    const [editOrganiserDialog, setEditOrganiserDialog] = useState(false);
    const [editOrganiserData, setEditOrganiserData] = useState(false);

    const columns = useMemo(() => [
        {
            Header: '#',
            Cell: (row) => <span>{Number(row.row.id) + 1}</span>
        },
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Mobile',
            accessor: 'mobile'
        },
        {
            Header: 'Created at',
            accessor: 'createdAt',
            Cell: ({ value }) => `${format(new Date(value), 'dd-MMM-yyyy')}`
        },
        {
            Header: 'Updated at',
            accessor: 'updatedAt',
            Cell: ({ value }) => `${format(new Date(value), 'dd-MMM-yyyy')}`
        },
        // {
        //     Header: 'Actions',
        //     accessor: d => d,
        //     Cell: ({ value }) => <div><CustomIconButton text="delete" onClick={() => onDeleteOrganiserHandle(value._id)} /> <CustomIconButton text="edit" onClick={() => onEditOrganiserHandle(value)} /></div>
        // }
    ], []);

    const fetchData = async () => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/users/organisers"
            })
            setOrganisers(res);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, []);

    const toggleOrganiserDialog = () => {
        setAddOrganiserDialog(!addOrganiserDialog)
    };

    const toggleEditOrganiserDialog = () => {
        setEditOrganiserDialog(!editOrganiserDialog)
    };

    const onEditOrganiserHandle = (value) => {
        setEditOrganiserData(value);
        toggleEditOrganiserDialog()
    };

    const onDeleteOrganiserHandle = async (id) => {
        try {
            await makeApiCall({
                method: "DELETE",
                path: `/organisers/${id}`
            })
            fetchData();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <SectionCard noPadding title="Organisers" addButtonText="Invite new Organizer" onAddButtonClick={toggleOrganiserDialog}>

                {loading && <TableLoader count={10} />}

                {organisers?.data?.length > 0 &&
                    <Table
                        columns={columns}
                        loading={loading}
                        data={organisers.data}
                    />
                }
            </SectionCard>

            <Modal title="Add Organizer" open={addOrganiserDialog} onClose={toggleOrganiserDialog}>
                <OrganiserForm type="add" getNewList={fetchData} closeModal={toggleOrganiserDialog} />
            </Modal>

            <Modal title="Edit Organizer" open={editOrganiserDialog} onClose={toggleEditOrganiserDialog}>
                <OrganiserForm type="edit" data={editOrganiserData} getNewList={fetchData} closeModal={toggleOrganiserDialog} />
            </Modal>
        </div>
    )
};