import React from 'react'
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Style from './index.module.scss';
import { CustomButton } from '..';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ children, open, onClose, onSubmit, title = "", submitBtnText = "Submit", closeBtnText = "close", maxWidth="md", fullWidth=false, minWidth="50vw", minHeight="50vh" }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth={false}
        fullWidth={fullWidth}
      >

        <div className={Style.modal}>
          <div className={Style.header}>
            <div>
              <h2>{title}</h2>
            </div>
            <div className={Style.close_button}>
              <span onClick={onClose}>
                <CloseIcon />
              </span>
            </div>
          </div>
          <div className={Style.body}  style={{minWidth, minHeight }}>
            {children}
          </div>
          {/* <div className={Style.footer}>
            <CustomButton onClick={onClose} variant="contained" color="error" size="small" text={closeBtnText} />
            <CustomButton onClick={onSubmit} variant="contained" color="success" size="small" text={submitBtnText} />
          </div> */}
        </div>
      </Dialog>
    </div>
  );
}

export default Modal;