/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import Style from './index.module.scss'
import { useSnackbar } from 'notistack';
import { CustomButton, CustomDropdown, Modal, FormInput, CustomIconButton, Loader, SectionCard } from '../../../components'
import { useAuth } from '../../../contexts/AuthContext';
import { makeApiCall } from '../../../utils/makeApiCall';
import Swal from 'sweetalert2'
import { Checkbox } from '@mui/material';


const PostForm = ({ type, getNewList, closeModal, data }) => {

    const defaultValue = {
        image: "",
        name: "",
        description: "",
        sell: false,
        price: ""
    }

    const [category, setCategory] = useState('')
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(defaultValue);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const inputs = [
        {
            id: 1,
            name: "image",
            label: "Image Url",
            required: true
        },
        {
            id: 2,
            name: "name",
            label: "Name",
            required: true
        },
        {
            id: 3,
            name: "description",
            label: "Description",
            multiline: true
        }
    ];

    const getCategories = async () => {
        const { data } = await makeApiCall({
            method: "GET",
            path: "/categories"
        })
        const options = data.map(d => ({ label: d.name, value: d._id }))
        setCategoryOptions(options)
    };

    const onChangeHande = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const onSellToggle = async () => {
        const newItems = { ...values };
        newItems.sell = !newItems.sell
        setValues(newItems);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            ...values,
            category: categoryOptions.value
        };

        if (type === 'add') {
            const res = await makeApiCall({
                method: "POST",
                path: "/posts",
                payload,
            })
            if (res.success) {
                enqueueSnackbar('New Art Added', { variant: 'success' });
                getNewList();
                closeModal();
                setLoading(false);
            } else {
                enqueueSnackbar(res?.error || 'Something went Wrong!', { variant: 'error' });
                setLoading(false);
            }
        };

        if (type === 'edit') {
            const res = await makeApiCall({
                method: "PUT",
                path: `/posts/${data.id}`,
                payload: values,
            })
            if (res.success) {
                enqueueSnackbar('Successfully Updated', { variant: 'success' });
                getNewList();
                closeModal();
                setLoading(false);
            } else {
                enqueueSnackbar(res?.error || 'Something went Wrong!', { variant: 'error' });
                setLoading(false);
            }
        };
        setValues(defaultValue);
    };

    useEffect(() => {
        if (type === 'edit') {
            setValues({
                image: data.image,
                name: data.name,
                description: data.description,
                sell: data.sell,
                price: data.price
            })
        }
        getCategories();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            {/* <CustomDropdown options={categoryOptions} placeholder="Select" onChange={value => setCategory(value)} value={category} /> */}
            {inputs.map((input) => (
                <FormInput
                    {...input}
                    key={input.id}
                    value={values[input.name]}
                    onChange={onChangeHande}
                    fullWidth
                    disabled={loading}
                />

            ))}
            <div>
                Want to sell this art ? <Checkbox checked={values.sell} onChange={onSellToggle} inputProps={{ 'aria-label': 'controlled' }} />
            </div>
            {values.sell ? <div>
                <FormInput label="Price" value={values.price} name="price" type="number" onChange={onChangeHande} fullWidth disabled={loading} />
            </div> : null}
            <div className="form_submit_button">
                <CustomButton variant="contained" color="success" size="small" text={type === 'add' ? 'Add' : 'Update'} type="submit" loading={loading} disabled={loading} />
            </div>
        </form>
    )
};


export default () => {
    const { currentUser } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ posts: [] });
    const [editPostDialog, setEditPostDialog] = useState(false);
    const [addPostDialog, setAddPostDialog] = useState(false);

    const [editPostData, setEditPostData] = useState({});

    const onChangeHande = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await makeApiCall({
            method: "PUT",
            path: '/auth/updateuserinfo',
            payload: {
                ...values,
                userId: currentUser._id
            }
        })

        if (res.success) {
            enqueueSnackbar('Registed successfully', { variant: 'success' });
        } else {
            enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        }
        setLoading(false);
    };

    const getPosts = async () => {
        setLoading(true);
        const res = await makeApiCall({
            method: "GET",
            path: `/posts/individual`
        })
        setValues({
            posts: res.success ? res.data : [],
        })
        setLoading(false);
    }

    useEffect(() => {
        getPosts();
    }, []);

    const onDeletePostHandle = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4B0082',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (result.isConfirmed) {

            const res = await makeApiCall({
                method: "DELETE",
                path: `posts/${id}`
            })

            if (res.success) {
                enqueueSnackbar('Post Deleted Successfully', { variant: 'success' });
                getPosts();
            } else {
                enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
            }
            setLoading(false);
        }
    };

    const toggleEditPostDialog = (fair) => {
        setEditPostData(fair);
        setEditPostDialog(!editPostDialog);
    };

    const toggleAddPostDialog = () => {
        setAddPostDialog(!addPostDialog)
    };


    return (

        <>
            <SectionCard noPadding title="My Arts" addButtonText="Add new Arts" onAddButtonClick={toggleAddPostDialog} defaultPadding>

                {loading ? <div className="loader_container"><Loader /></div> :
                    <div className={Style.post_tab_container}>
                        <div className={Style.post_container}>
                            {values?.posts.map(post =>
                                <div key={post._id} className={Style.post_card}>
                                    <img src={post.image} alt="" />
                                    <p>Name: <span>{post.name}</span></p>
                                    <p>Description: <span>{post.description}</span></p>
                                    {/* <p>Category : <span>{post.category?.name}</span></p> */}
                                    {post.sell ? <p>This art is availeble for Sell</p> : ""}
                                    {post.sell ? <p>Selling Price: <span>₹{post.price}</span></p> : ""}
                                    <div className={Style.post_delete}>
                                        {currentUser.id === post.createdBy ? <>
                                            <CustomIconButton text="edit" onClick={() => toggleEditPostDialog(post)} />
                                            <CustomIconButton text="delete" onClick={() => onDeletePostHandle(post.id)} />
                                        </> : ''}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </SectionCard>
            <Modal title="Add Arts" open={addPostDialog} onClose={toggleAddPostDialog}>
                <PostForm type="add" getNewList={getPosts} closeModal={toggleAddPostDialog} />
            </Modal>

            <Modal title={'Edit ' + editPostData?.name} open={editPostDialog} onClose={toggleEditPostDialog}>
                <PostForm type="edit" getNewList={getPosts} closeModal={toggleEditPostDialog} data={editPostData} />
            </Modal>
        </>
    )
};