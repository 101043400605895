import React from 'react';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'


const MyEditor = ({ value = '', onChange = () => { }, placeholder = "Add your description" }) => {

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'script': 'sub' }, { 'script': 'super' }],
            ['link'],
            [{ align: [] }, { color: [] }, { background: [] }],
            ['clean']
        ],
    }
    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', "image", "align", "color", "background"
    ]

    return (<div>
        <ReactQuill
            value={value}
            modules={modules}
            formats={formats}
            onChange={onChange}
            placeholder={placeholder}
            theme="snow"
        />
    </div>)
};

export default MyEditor;
