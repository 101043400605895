import React from 'react'
import Style from './index.module.scss';
import { TextField, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';





// variant : outlined, filled, standard
const FormInput = ({ label = '', variant = "outlined", multiline = false, rows = 4, size = "small", fullwidth = false, onChange = () => { }, value = '', style = {}, icon = undefined, minRows = undefined, maxRows = undefined, disabled = undefined, type = "text", ...inputProps }) => {

  // if (type === 'switch') {
  //   return (
  //     <FormControlLabel control={<Switch {...inputProps} />} label={label} />
  //   )
  // } 

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      fullWidth={fullwidth}
      style={style}
      multiline={multiline}
      rows={rows}
      variant={variant}
      size={size}
      disabled={disabled}
      minRows={minRows}
      maxRows={maxRows}
      margin="dense"
      {...inputProps}
    />
  )
}

export default FormInput;
