/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback } from 'react';
import ReactSelect, { components, StylesConfig } from "react-select";
import AsyncSelect from 'react-select/async';
import Style from './index.module.scss';
import * as faIcons from "react-icons/fa";
// import { util } from "../../commonLib";
// import { FaBeer } from 'react-icons/fa';
import debounce from 'lodash.debounce';

const Input = (props) => <components.Input {...props} isHidden={false} />;

export default ({ options, icon = undefined, containerStyle = {}, type = 'default', className = '', classNamePrefix = 'as_select', isDisabled = false, isClearable = false, isSearchable = false, placeholder = '', formatOptionLabel = undefined, onChange = undefined, value = undefined, defaultValue = undefined, style = {}, loadOptions = undefined, noOptionsMessage = "No results found", defaultOptions = undefined, onInputChange = undefined, inputValue = undefined, onFocus = undefined, name = undefined }) => {
  const newLoadOptions = (query, callback) => {
    loadOptions(query).then(response => callback(response))
  }
  const debouncedOptions = debounce(newLoadOptions, 600);
  const memoisedOptions = useCallback(debouncedOptions, [debouncedOptions])
  // const Icon = util.getIcon(icon);

  if (type === 'async' && !defaultOptions) {
    return (
      <AsyncSelect
        className={'as_async_select'}
        classNamePrefix={'as_select'}
        placeholder={placeholder}
        onChange={onChange}
        loadOptions={memoisedOptions}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={({ inputValue }) => !inputValue ? null : noOptionsMessage}
        isClearable={isClearable}
        styles={{
          ...style,
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }} isDisabled={isDisabled}
        value={value}
      />
    )
  } else if (type === 'async' && defaultOptions) {
    return (
      <AsyncSelect
        // cacheOptions
        className={'as_async_select'}
        classNamePrefix={'as_select'}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        onChange={onChange}
        onFocus={onFocus}
        onInputChange={onInputChange}
        loadOptions={memoisedOptions}
        defaultOptions={defaultOptions}
        value={value}
        inputValue={inputValue}
        controlShouldRenderValue={false}
        components={{ Input }}
        styles={{
          ...style,
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    )
  } else {
    return (
      <div className={Style.dropdown_container} style={containerStyle}>
        {icon && <span className={Style.icon}> </span>}
        <ReactSelect
          className={className}
          name={name}
          classNamePrefix={'as_select'}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          value={value || ""}
          defaultValue={defaultValue}
          styles={{
            ...style,
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          formatOptionLabel={formatOptionLabel}
        />
      </div>
    )
  }

};