/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useAuth } from '../../../contexts/AuthContext';
import Style from './index.module.scss';
import { CustomButton, Loader, Modal, SectionCard } from '../../../components'
import { makeApiCall } from '../../../utils/makeApiCall';
import { Avatar, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import { wallOptions, roofOptions, floorOptions } from '../../../data'

const AddBox = ({ buttonText, onSubmitButtonClick, data = "", placeholder = "" }) => {
    const [value, setValue] = useState('');
    const onSubmitHandle = () => {
        if (value) {
            onSubmitButtonClick({ value, data });
            setValue('');
        }
    };

    return <div className={Style.add_box_container}>
        <input value={value} onChange={(e) => setValue(e.target.value)} required placeholder={placeholder} />
        <CustomButton text={buttonText ? buttonText : 'Submit'} onClick={onSubmitHandle} />
    </div>
};

const OptionSelect = ({ options, selectedImg, onOptionChange, name, roomId }) => {
    return (
        <div className={Style.select_option_container}>
            {options.length > 0 && options.map(option =>
                <span className={`${Style.select_option} ${option.image === selectedImg ? Style.selected : ""}`} onClick={() => onOptionChange(roomId, name, option.image)}>
                    <img src={option.image} alt={option.name} />
                    {option.image === selectedImg ? <div className={Style.option_overlay}> <CheckCircleIcon /></div> : null}
                </span>
            )}
        </div>
    )
};

const ManageSponsors = ({ spnsrs, roomId, onSubmitSponsorHandle, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [sponsors, setSponsors] = useState({});
    const fetchData = async () => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/users/sponsors"
            })
            const filtered = res?.data?.length > 0 && res.data.map(item => ({ ...item, isChecked: spnsrs.some(({ id: idB }) => item.id === idB) }))
            setSponsors(filtered);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, []);

    const onChangeHande = async (id) => {
        const item = sponsors.find((item) => item.id === id);
        const newItems = [...sponsors];
        newItems.find(item => item.id === id).isChecked = !item.isChecked
        setSponsors(newItems);
    };

    const handleSubmit = async (e) => {
        const filteredSponsors = sponsors.filter(s => s.isChecked);
        onSubmitSponsorHandle(roomId, filteredSponsors)
        closeModal();
    };


    return (
        <div>
            {loading ? <div className="loader_container"><Loader /></div> :
                <>
                    {sponsors?.length > 0 &&
                        <div>
                            {sponsors.map(sponsor =>
                                <div className={Style.card_container}>
                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                        <Avatar src={sponsor.logo ? sponsor.logo : sponsor.name.charAt(0)} sx={{ bgcolor: '#f44336' }} alt={sponsor.name} variant="square" />
                                        {sponsor.name}
                                    </div>
                                    <Checkbox checked={sponsor.isChecked} onChange={() => onChangeHande(sponsor.id)} inputProps={{ 'aria-label': 'controlled' }} />

                                </div>
                            )}

                            <div className="pull_right_container mt_3">
                                <CustomButton text="Update" onClick={handleSubmit} variant="contained" color="success" startIcon={<SaveIcon />} />
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
};

const ManagePosts = ({ psts, roomId, onSubmitPostHandle, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState({});

    const fetchData = async () => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: '/posts/individual'
            })

            console.log("psts=====>", psts)
            console.log("res?.data=====>", res?.data)

            const filtered = res?.data?.length > 0 && res.data.map(item => ({ ...item, isChecked: psts.some(({ id: idB }) => item.id === idB) }));
            setPosts(filtered);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        setLoading(true)
        fetchData();
    }, []);

    const onPostSelected = (id) => {
        const item = posts.find((item) => item.id === id);
        const newPosts = [...posts];
        newPosts.find(item => item.id === id).isChecked = !item.isChecked
        setPosts(newPosts);
    };

    const handleSubmit = async (e) => {
        const filteredPosts = posts.filter(a => a.isChecked);
        onSubmitPostHandle(roomId, filteredPosts)
        closeModal();
    };

    return (
        <>
            {loading ? <div className="loader_container"><Loader /></div> :
                <div>
                    <div className={Style.post_card_container}>
                        {posts?.length > 0 &&
                            <div className={Style.post_card_container}>
                                {posts.map(post =>
                                    <div className={`${Style.post_card} ${post.isChecked ? Style.selected : ""}`} onClick={() => onPostSelected(post.id)}>
                                        <img src={post.image} alt={post?.name} />
                                        <h5>{post?.name}</h5>

                                        {post.isChecked && <div className={Style.post_card_overlay}> <CheckCircleIcon /></div>}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="pull_right_container mt_3">
                        <CustomButton text="Update" onClick={handleSubmit} variant="contained" color="success" startIcon={<SaveIcon />} />
                    </div>
                </div>
            }
        </>
    )
};

const ManageAgendas = ({ agnds, roomId, onSubmitAgendaHandle, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [agendas, setAgendas] = useState({});
    const fetchData = async () => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/agendas"
            })
            const filtered = res?.data?.length > 0 && res.data.map(item => ({ ...item, isChecked: agnds.some(({ id: idB }) => item.id === idB) }))
            setAgendas(filtered);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const onChangeHande = async (id) => {
        const item = agendas.find((item) => item.id === id);
        const newItems = [...agendas];
        newItems.find(item => item.id === id).isChecked = !item.isChecked
        setAgendas(newItems);
    };

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, []);

    const handleSubmit = async (e) => {
        const filteredAgendas = agendas.filter(a => a.isChecked);
        onSubmitAgendaHandle(roomId, filteredAgendas)
        closeModal();
    };

    return (
        <>
            {loading ? <div className="loader_container"><Loader /></div> :
                <div>

                    {agendas?.length > 0 &&
                        <div>
                            {agendas.map(agenda =>
                                <div className={Style.card_container}>
                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', width: '100%', justifyContent: 'space-between', paddingLeft: '10px' }}>
                                        {agenda.eventName} By {agenda.speakerName}
                                        <Checkbox checked={agenda.isChecked} onChange={() => onChangeHande(agenda.id)} inputProps={{ 'aria-label': 'controlled' }} />
                                    </div>
                                </div>
                            )}
                        </div>
                    }

                    <div className="pull_right_container mt_3">
                        <CustomButton text="Update" onClick={handleSubmit} variant="contained" color="success" startIcon={<SaveIcon />} />
                    </div>
                </div>
            }
        </>
    )
};

export default () => {
    const params = useParams();
    const { currentUser } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [editSponsorData, setEditSponsorData] = useState(null);
    const [editPostData, setEditPostData] = useState(null);
    const [editAgendaData, setEditAgendaData] = useState(null);

    const [manageSponsorDialog, setManageSponsorDialog] = useState(false);
    const [managePostDialog, setManagePostDialog] = useState(false);
    const [manageAgendaDialog, setManageAgendaDialog] = useState(false);


    const getPosts = async () => {
        try {
            setLoading(true);
            const res = await makeApiCall({
                method: "GET",
                path: `/rooms?artistId=${currentUser?.id}`
            })
            if (res?.data) {
                setRooms(res.data);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getPosts();
    }, []);

    const createRoom = async ({ value }) => {
        // const newRooms = [...rooms];
        // newRooms.push({
        //     name: value,
        //     posts: [],
        //     sponsors: [],
        //     agendas: [],
        //     // styles: {
        //     //     floor: "https://raw.githubusercontent.com/HidsTechnologies/images/main/floor/8.png",
        //     //     left_wall: "https://raw.githubusercontent.com/HidsTechnologies/images/main/wall/5.png",
        //     //     middle_wall: "https://raw.githubusercontent.com/HidsTechnologies/images/main/wall/5.png",
        //     //     right_wall: "https://raw.githubusercontent.com/HidsTechnologies/images/main/wall/5.png",
        //     //     roof: "https://raw.githubusercontent.com/HidsTechnologies/images/main/roof/1.png"
        //     // }
        // })
        // setRooms(newRooms);

        try {
            const res = await makeApiCall({
                method: "POST",
                path: `/rooms`,
                payload: {
                    name: value
                }
            })
            getPosts();
            // console.log("res=======>", res)
            // const filtered = res?.data?.length > 0 && res.data.map(item => ({ ...item, isChecked: psts.some(({ _id: idB }) => item._id === idB) }))
            // setPosts(filtered);

            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const onRoomNameChange = (value, id) => {
        const newRooms = [...rooms]
        const filteredRoom = newRooms.filter(nr => nr.id === id);
        filteredRoom[0].name = value;
        setRooms(newRooms)
    };

    const toggleManageSponsorDialog = (data) => {
        setEditSponsorData(data);
        setManageSponsorDialog(!manageSponsorDialog)
    };

    const toggleManageAgendaDialog = (data) => {
        setEditAgendaData(data);
        setManageAgendaDialog(!manageAgendaDialog)
    };

    const toggleManagePostDialog = (data) => {
        console.log("data====1==>", data)
        setEditPostData(data);
        setManagePostDialog(!managePostDialog)
    };

    const onStyleChange = (roomId, name, url) => {

        const newRooms = [...rooms];
        const filteredRoom = newRooms.filter(nr => nr.id === roomId);
        filteredRoom[0].styles[name] = url;
        setRooms(newRooms);
    };

    const onSubmitSponsorHandle = (roomId, sponsors) => {
        const newRooms = [...rooms];
        const filteredRoom = newRooms.filter(nr => nr.id === roomId);
        filteredRoom[0].sponsors = sponsors
        setRooms(newRooms);
    };

    const onSubmitPostHandle = (roomId, posts) => {
        const newRooms = [...rooms];
        const filteredRoom = newRooms.filter(nr => nr.id === roomId);
        filteredRoom[0].posts = posts;
        setRooms(newRooms);
    };

    const onSubmitAgendaHandle = (roomId, agendas) => {
        const newRooms = [...rooms];
        const filteredRoom = newRooms.filter(nr => nr.id === roomId);
        filteredRoom[0].agendas = agendas;
        setRooms(newRooms);
    };

    const updateRoom = async ({ room }) => {

        const payload = {
            name: room.name,
            posts: room.posts.map(r => r.id),
            sponsors: room.sponsors.map(s => s.id),
            agendas: room.agendas.map(a => a.id)
        }

        try {
            setLoading(true);
            const res = await makeApiCall({
                method: "PUT",
                path: `/rooms/${room.id}`,
                payload
            })

            getPosts()
            // if(res?.data){
            //     setRooms(res.data);
            // }
            setLoading(false);
        } catch (err) {
            setLoading(true);
            console.error(err);
        }
    };

    console.log("rooms======>", rooms)
    return (
        <>
            <SectionCard title="My Rooms" defaultPadding>
                {loading ?
                    <div className="loader_container"><Loader /></div>
                    :
                    <div>
                        <div className={Style.create_room}>
                            <AddBox buttonText="Create New Room" onSubmitButtonClick={createRoom} placeholder="Type Room Name" />
                        </div>

                        {rooms?.length > 0 && rooms.map(room =>
                            <div className={Style.room_container} key={room?.id}>
                                <div className={Style.room_title}><input value={room?.name} onChange={(e) => onRoomNameChange(e.target.value, room?.id)} /></div>
                                {/* <div className={Style.roof_container}>
                                <span>Roof:</span> <OptionSelect options={roofOptions} selectedImg={room?.styles?.roof} onOptionChange={onStyleChange} name="roof" roomId={room.id} />
                            </div> */}
                                <div className={Style.wall_container}>
                                    {/* <SectionCard title="Left Wall">
                                    <OptionSelect options={wallOptions} selectedImg={room?.styles?.left_wall} onOptionChange={onStyleChange} name="left_wall" roomId={room.id} />
                                    <div className={Style.body_container}>
                                        {room?.sponsors?.length === 0 ? 'You have not added any Sponsors yet' : null}
                                        <div>
                                            {room?.sponsors?.length > 0 && room.sponsors.map(sponsor =>
                                                <div className={Style.sponsor_list}>
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                        <Avatar src={sponsor.logo ? sponsor.logo : sponsor.name.charAt(0)} sx={{ bgcolor: '#16455e' }} alt={sponsor.name} variant="square" />
                                                        {sponsor?.name}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pull_right_container mt_3">
                                        <CustomButton fullWidth variant="outlined" text="Manage Sponsors" onClick={() => toggleManageSponsorDialog(room)} />
                                    </div>
                                </SectionCard> */}
                                    <SectionCard>
                                        {/* <OptionSelect options={wallOptions} selectedImg={room?.styles?.middle_wall} onOptionChange={onStyleChange} name="middle_wall" roomId={room.id} /> */}
                                        <div className={Style.body_container}>
                                            {console.log("===room==>", room)}
                                            {room?.posts?.length === 0 ? 'You have not added any Art yet' : null}
                                            <div className={Style.post_card_container}>
                                                {room?.posts?.length > 0 && room.posts.map(post =>
                                                    <div className={Style.post_card}>
                                                        <img src={post?.image} alt={post?.name} />
                                                        <h5>{post?.name}</h5>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="pull_right_container mt_3">
                                            <CustomButton fullWidth variant="outlined" text="Manage Arts" onClick={() => toggleManagePostDialog(room)} />
                                            <CustomButton fullWidth variant="outlined" text="Update Room" onClick={() => updateRoom({ room })} />
                                        </div>
                                    </SectionCard>
                                    {/* <SectionCard title="Right Wall">
                                    <OptionSelect options={wallOptions} selectedImg={room?.styles?.right_wall} onOptionChange={onStyleChange} name="right_wall" roomId={room.id} />
                                    <div className={Style.body_container}>
                                        {room?.agendas?.length === 0 ? 'You have not added any Agendas yet' : null}
                                        <div>
                                            {room?.agendas?.length > 0 && room.agendas.map(agenda =>
                                                <div className={Style.agenda_list}>
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                        {agenda.eventName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pull_right_container mt_3">
                                        <CustomButton fullWidth variant="outlined" text="Manage Agendas" onClick={() => toggleManageAgendaDialog(room)} />
                                    </div>
                                </SectionCard> */}
                                </div>
                                {/* <div className={Style.floor_container}>
                                <span>Floor:</span> <OptionSelect options={floorOptions} selectedImg={room?.styles?.floor} onOptionChange={onStyleChange} name="floor" roomId={room.id} />
                            </div> */}
                            </div>
                        )}
                    </div>
                }


                {/* <Modal title={`Manage Sponsors for ${editSponsorData?.name}`} open={manageSponsorDialog} onClose={toggleManageSponsorDialog}>
                    <ManageSponsors roomId={editSponsorData?.id} spnsrs={editSponsorData?.sponsors} onSubmitSponsorHandle={onSubmitSponsorHandle} closeModal={toggleManageSponsorDialog} />
                </Modal> */}

                <Modal title={`Manage Posts for ${editPostData?.name}`} open={managePostDialog} onClose={toggleManagePostDialog}>
                    <ManagePosts roomId={editPostData?.id} psts={editPostData?.posts} onSubmitPostHandle={onSubmitPostHandle} closeModal={toggleManagePostDialog} />
                </Modal>

                {/* <Modal title={`Manage Agendas for ${editAgendaData?.name}`} open={manageAgendaDialog} onClose={toggleManageAgendaDialog}>
                    <ManageAgendas roomId={editAgendaData?.id} agnds={editAgendaData?.agendas} onSubmitAgendaHandle={onSubmitAgendaHandle} closeModal={toggleManageAgendaDialog} />
                </Modal> */}

            </SectionCard>
        </>
    )
};