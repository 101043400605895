/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';
import Style from './index.module.scss';
import { CardLoader, CustomButton, FormInput, Modal, SectionCard, Editor, CustomDropdown } from '../../../components'
import { makeApiCall } from '../../../utils/makeApiCall';
import { unescapeHTML, AGE_GROUP_OPTIONS } from '../../../utils'
import Swal from 'sweetalert2';
import { Avatar, Card, CardHeader, CardMedia, IconButton, Menu, MenuItem, Checkbox } from '@mui/material';
import { red } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import moment from 'moment';

const CompetitionForm = ({ type, getNewList, closeModal, data }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        name: "",
        thumbnail: "",
        start_date: "",
        end_date: "",
        meeting_link: "",
        prize_money: "",
        entry_fee: "",
        publish: true
    });
    const [description, setDescription] = useState("")
    const [ageGroup, setAgeGroup] = useState(null);

    const onChangeHandle = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const onDescriptionChangeHandle = (content, delta, source, editor) => {
        setDescription(editor.getHTML());
    };

    const toggleChangeHandle = (e) => {
        const newValues = { ...values };
        newValues[e.target.name] = !(newValues[e.target.name])
        setValues(newValues);
    };

    const inputs = [
        {
            id: 1,
            name: "name",
            label: "Name",
            errormessage:
                "Name should be 3-16 characters and shouldn't include any special character!",
            pattern: "^[A-Za-z0-9]{3,16}$",
            required: true,
        },
        {
            id: 2,
            name: "start_date",
            label: "Starts Time",
            type: "datetime-local",
            required: true,
            focused: true
        },
        {
            id: 3,
            name: "end_date",
            label: "End Time",
            type: "datetime-local",
            required: true,
            focused: true
        },
        {
            id: 4,
            name: "thumbnail",
            label: "Thumbnail",
            required: true
        },
        {
            id: 5,
            name: "meeting_link",
            label: "Meeting Link"
        },
        {
            id: 6,
            name: "prize_money",
            label: "Prize Money",
            type: "number"
        },
        {
            id: 7,
            name: "entry_fee",
            label: "Entry Fee",
            type: "number"
        }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (type === 'add') {
            const res = await makeApiCall({
                method: "POST",
                path: "/competitions",
                payload: {
                    ...values,
                    description,
                    ageGroup: ageGroup.value
                },
            })
            if (res.success) {
                enqueueSnackbar('New Competition Added', { variant: 'success' });
                getNewList();
                closeModal();
                setLoading(false);
            } else {
                enqueueSnackbar(res?.error || 'Something went Wrong', { variant: 'error' });
                setLoading(false);
            }
        };

        if (type === 'edit') {
            const res = await makeApiCall({
                method: "PUT",
                path: `/competitions/${data.id}`,
                payload: {
                    ...values,
                    description,
                    ageGroup: ageGroup.value
                },
            })
            if (res.success) {
                enqueueSnackbar('Successfully Updated', { variant: 'success' });
                getNewList();
                closeModal();
                setLoading(false);
            } else {
                enqueueSnackbar(res?.error || 'Something went Wrong', { variant: 'error' });
                setLoading(false);
            }
        };

    };

    useEffect(() => {
        if (type === 'edit') {
            setValues({
                name: data.name,
                thumbnail: data.thumbnail,
                start_date: moment(data.start_date).format('YYYY-MM-DDTHH:mm'),
                end_date: moment(data.end_date).format('YYYY-MM-DDTHH:mm'),
                meeting_link: data.meeting_link,
                prize_money: data.prize_money,
                entry_fee: data.entry_fee,
                publish: data.publish
            })
            setDescription(unescapeHTML(data?.description))
            if (data?.ageGroup) {
                setAgeGroup({ label: AGE_GROUP_OPTIONS.filter(ag => ag.value === data.ageGroup)[0].label, value: data.ageGroup })
            }
        }
    }, []);

    return (
        <div className={Style.form_container}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <div style={{ flex: '1' }}>
                        <div style={{ background: '#fff', padding: '10px', height: '100%' }}>
                            {inputs.map((input) => (
                                <FormInput
                                    {...input}
                                    key={input.id}
                                    value={values[input.name]}
                                    onChange={onChangeHandle}
                                    fullWidth
                                    disabled={loading}
                                />
                            ))}
                            <div style={{ marginTop: '10px' }}>
                                Age group:
                                <CustomDropdown options={AGE_GROUP_OPTIONS} placeholder="Select Age Group" onChange={value => setAgeGroup(value)} value={ageGroup} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                Description:
                                <Editor value={description} onChange={onDescriptionChangeHandle} placeholder="Add your description" />
                            </div>
                            <div>
                                Publish
                                <Checkbox checked={values?.publish} onChange={toggleChangeHandle} name="publish" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form_submit_button">
                    <CustomButton variant="contained" color="success" size="small" text={type === 'add' ? 'Add' : 'Update'} type="submit" loading={loading} disabled={loading} />
                </div>
            </form>
        </div>
    );
};

const CompetitionCard = ({ competition, getNewList, onEditCompetition = undefined }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const onActionHandle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onActionClose = () => {
        setAnchorEl(null);
    };

    const onDeleteCompetitionHandle = async () => {
        onActionClose()

        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4B0082',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (result.isConfirmed) {
            try {
                const res = await makeApiCall({
                    method: "DELETE",
                    path: `competitions/${competition.id}`
                })
                if (res.success) {
                    getNewList();
                    enqueueSnackbar('Competition Deleted Successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
                }

            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <Card className={Style.card_wrapper}>
            <CardHeader
                avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe"> {competition?.name.charAt(0)} </Avatar>}
                action={<>
                    <IconButton aria-label="action" onClick={onActionHandle}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={onActionClose}
                    >
                        <MenuItem onClick={onEditCompetition}>Edit</MenuItem>
                        <MenuItem onClick={onDeleteCompetitionHandle}>Delete</MenuItem>
                    </Menu>
                </>}
                title={competition.name}
                subheader={`${moment(competition.start_date).format('DD/MM/yyyy HH:mm:ss')} - ${moment(competition.end_date).format('DD/MM/yyyy HH:mm:ss')}`}
            />
            <CardMedia component="img" height="194" image={competition.thumbnail} alt={competition.name} loading="lazy" />

            <div className={Style.card_info}>
                <div dangerouslySetInnerHTML={{ __html: unescapeHTML(competition.description) }} />
            </div>

        </Card>
    )
};

export default () => {
    const [competitions, setCompetition] = useState([])
    const [loading, setLoading] = useState(false);
    const [addCompetitionDialog, setAddCompetitionDialog] = useState(false);
    const [editCompetitionDialog, setEditCompetitionDialog] = useState(false);
    const [editCompetitionData, setEditCompetitionData] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/competitions/individual"
            })
            setCompetition(res);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    const toggleAddCompetitionDialog = () => {
        setAddCompetitionDialog(!addCompetitionDialog)
    };

    const toggleEditCompetitionDialog = (competition) => {
        setEditCompetitionData(competition);
        setEditCompetitionDialog(!editCompetitionDialog);
    };

    return (
        <div>
            <SectionCard noPadding title="Competitions" addButtonText="Add New Competition" onAddButtonClick={toggleAddCompetitionDialog}>
                <div className={Style.container}>
                    {!loading && competitions?.data?.length === 0 && <div style={{ width: "100%", background: '#fff', padding: '5%', textAlign: 'center' }}>
                        <p> You Have not created any competition yet . <br /><br /> <CustomButton text="Create One" onClick={toggleAddCompetitionDialog} /> </p>
                    </div>}                    {loading ? <CardLoader count={2} /> :
                        (competitions?.data?.length > 0 && competitions.data.map(competition =>
                            <CompetitionCard competition={competition} getNewList={fetchData} key={competition.id} onEditCompetition={() => toggleEditCompetitionDialog(competition)} />)
                        )}
                </div>
            </SectionCard>

            <Modal title="Add New Competition" open={addCompetitionDialog} onClose={toggleAddCompetitionDialog}>
                <CompetitionForm type="add" getNewList={fetchData} closeModal={toggleAddCompetitionDialog} />
            </Modal>

            <Modal title={'Edit ' + editCompetitionData?.name} open={editCompetitionDialog} onClose={toggleEditCompetitionDialog}>
                <CompetitionForm type="edit" getNewList={fetchData} closeModal={toggleEditCompetitionDialog} data={editCompetitionData} />
            </Modal>
        </div>
    )
};