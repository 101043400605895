import React from 'react';
import Style from './index.module.scss';

const CounterCard = ({ count, title, icon }) => {
  return (
    <div className={Style.container}>
      <div className={Style.icon}>{icon}</div>
      <div className={Style.content}>
        <h3>{count}</h3>
        <h4>{title}</h4>
      </div>
    </div>
  )
}

export default CounterCard;
