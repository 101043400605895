import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Style from './index.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ColorButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  boxShadow: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.5,
  '&:active': {
    boxShadow: 'none',
  },
  '&:hover': {
    boxShadow: 'none',
  }
}));


//variant = "text" | "outlined" | "contained"'
//type = "button" | "submit" | "reset"


export const CustomButton = ({ text, disabled = false, variant = "contained", onClick = undefined, startIcon = undefined, endIcon = undefined, loading = undefined, loadingposition = "end", type = "button", classNames = "", style = {}, fullWidth=false, color="primary", rest }) => {
  return (
    <ColorButton disabled={disabled}
      variant={variant}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      loadingposition={loadingposition}
      type={type}
      className={classNames}
      style={style}
      fullWidth={fullWidth}
      color={color}
      {...rest}
    >

      {loading && <CircularProgress size={14} style={{ color: '#fff', marginRight: '5px' }} />}
      {text}
    </ColorButton>
  )
}


export const CustomIconButton = ({ text, onClick = () => { } }) => {
  return (
    <IconButton aria-label={text} size="small" onClick={onClick}>
      {text === 'delete' && <DeleteIcon fontSize="inherit" />}
      {text === 'edit' && <EditIcon fontSize="inherit" />}
    </IconButton>
  )
}
