/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react';
import Style from './index.module.scss';
import { makeApiCall } from '../../../utils/makeApiCall';
import { unescapeHTML } from '../../../utils'
import { CardLoader, CustomButton, FormInput, Modal, SectionCard, Editor } from '../../../components'
import Swal from 'sweetalert2';
import { Avatar, Card, CardHeader, CardMedia, IconButton, Menu, MenuItem, Typography, Checkbox } from '@mui/material';
import { red } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import moment from 'moment';

const CourseForm = ({ type, getNewList, closeModal, data }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        name: "",
        thumbnail: "",
        publish: true
    });
    const [description, setDescription] = useState("")


    const onChangeHandle = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };


    const onDescriptionChangeHandle = (content, delta, source, editor) => {
        setDescription(editor.getHTML());
    };

    const toggleChangeHandle = (e) => {
        const newValues = { ...values };
        newValues[e.target.name] = !(newValues[e.target.name])
        setValues(newValues);
    };

    const inputs = [
        {
            id: 1,
            name: "name",
            label: "Title",
            errormessage:
                "Name should be 3-16 characters and shouldn't include any special character!",
            pattern: "^[A-Za-z0-9]{3,16}$",
            required: true,
        },
        {
            id: 4,
            name: "thumbnail",
            label: "Thumbnail",
            required: true
        }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (type === 'add') {
            const res = await makeApiCall({
                method: "POST",
                path: "/courses",
                payload: {
                    ...values,
                    description
                },
            })
            if (res.success) {
                enqueueSnackbar('New Course Added', { variant: 'success' });
                getNewList();
                closeModal();
                setLoading(false);
            } else {
                enqueueSnackbar(res?.error || 'Something went Wrong', { variant: 'error' });
                setLoading(false);
            }
        };

        if (type === 'edit') {
            const res = await makeApiCall({
                method: "PUT",
                path: `/courses/${data.id}`,
                payload: {
                    ...values,
                    description
                },
            })
            if (res.success) {
                enqueueSnackbar('Successfully Updated', { variant: 'success' });
                getNewList();
                closeModal();
                setLoading(false);
            } else {
                enqueueSnackbar(res?.error || 'Something went Wrong', { variant: 'error' });
                setLoading(false);
            }
        };

    };

    useEffect(() => {
        if (type === 'edit') {
            setValues({
                name: data.name,
                thumbnail: data.thumbnail,
                start_date: moment(data.start_date).format('YYYY-MM-DDTHH:mm'),
                end_date: moment(data.end_date).format('YYYY-MM-DDTHH:mm'),
                publish: data.publish,
                course: data.course
            })
            setDescription(unescapeHTML(data?.description))
        }
    }, []);

    return (
        <div className={Style.course_form_container}>
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* <div style={{ background: '#fff', padding: '10px', height: '100%', width: "40%" }}>
                        
                        <div>
                            Publish
                            <Checkbox checked={values?.publish} onChange={toggleChangeHandle} name="publish" />
                        </div>
                    </div> */}
                    <div style={{ background: '#fff', padding: '10px', height: '100%' }}>
                        {inputs.map((input) => (
                            <FormInput
                                {...input}
                                key={input.id}
                                value={values[input.name]}
                                onChange={onChangeHandle}
                                fullWidth
                                disabled={loading}
                            />
                        ))}
                        <div style={{ marginTop: '10px' }}>
                            Description:
                            <Editor value={description} onChange={onDescriptionChangeHandle} placeholder="Add your description" />
                        </div>
                    </div>
                </div>
                <div className="form_submit_button">
                    <CustomButton variant="contained" color="success" size="small" text={type === 'add' ? 'Add' : 'Update'} type="submit" loading={loading} disabled={loading} />
                </div>
            </form>
        </div>
    );
};

const CourseCard = ({ course, getNewList, onEditCourse = undefined }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const onActionHandle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onActionClose = () => {
        setAnchorEl(null);
    };

    const onDeleteCourseHandle = async () => {
        onActionClose()

        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4B0082',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (result.isConfirmed) {
            try {
                const res = await makeApiCall({
                    method: "DELETE",
                    path: `courses/${course.id}`
                })
                if (res.success) {
                    getNewList();
                    enqueueSnackbar('Course Deleted Successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
                }

            } catch (err) {
                console.error(err);
            }
        }
    };


    return (
        <Card className={Style.card_wrapper}>
            <CardHeader
                avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe"> {course?.name.charAt(0)} </Avatar>}
                action={<>
                    <IconButton aria-label="action" onClick={onActionHandle}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={onActionClose}
                    >
                        <MenuItem onClick={onEditCourse}>Edit</MenuItem>
                        <MenuItem onClick={onDeleteCourseHandle}>Delete</MenuItem>
                    </Menu>
                </>}
                title={course.name}
            />
            <CardMedia component="img" height="194" image={course.thumbnail} alt={course.name} loading="lazy" />

            <div className={Style.card_info}>
                <div dangerouslySetInnerHTML={{ __html: unescapeHTML(course.description) }} />
            </div>

        </Card>
    )
};

export default () => {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(false);
    const [addCourseDialog, setAddCourseDialog] = useState(false);
    const [editCourseDialog, setEditCourseDialog] = useState(false);
    const [editCourseData, setEditCourseData] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/courses"
            })
            setCourses(res);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    const toggleAddCourseDialog = () => {
        setAddCourseDialog(!addCourseDialog)
    };

    const toggleEditCourseDialog = (course) => {
        setEditCourseData(course);
        setEditCourseDialog(!editCourseDialog);
    };

    return (
        <div>
            <SectionCard noPadding title="Courses" addButtonText="Add New Course" onAddButtonClick={toggleAddCourseDialog}>
                <div className={Style.container}>
                    {!loading && courses?.data?.length === 0 && <div style={{ width: "100%", background: '#fff', padding: '5%', textAlign: 'center' }}>
                        <p> You have not created any course yet . <br /><br /> <CustomButton text="Create One" onClick={toggleAddCourseDialog} /> </p>
                    </div>}                    {loading ? <CardLoader count={2} /> :
                        (courses?.data?.length > 0 && courses.data.map(course =>
                            <CourseCard course={course} getNewList={fetchData} key={course.id} onEditCourse={() => toggleEditCourseDialog(course)} />)
                        )}
                </div>
            </SectionCard>



            <Modal title="Add New Course" open={addCourseDialog} onClose={toggleAddCourseDialog}>
                <CourseForm type="add" getNewList={fetchData} closeModal={toggleAddCourseDialog} />
            </Modal>

            <Modal title={'Edit ' + editCourseData?.name} open={editCourseDialog} onClose={toggleEditCourseDialog}>
                <CourseForm type="edit" getNewList={fetchData} closeModal={toggleEditCourseDialog} data={editCourseData} />
            </Modal>
        </div>
    )
};