import React from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"

export default function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  const location = useLocation();
  return (
    currentUser ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
  )
}