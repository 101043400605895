import axios from "axios";
import { getAccessToken } from "./storage";
import { API_BASE_URL } from '../utils'

const platform = axios.create({
  baseURL: `${API_BASE_URL}/api/v1`,
  timeout: 60000
});

axios.defaults.headers.post["Content-Type"] = "application/json";

export const makeApiCall = async ({
  method,
  path,
  baseURL = undefined,
  params = undefined,
  headers = undefined,
  payload = undefined,
  responseType = undefined
}) => {

  const accessToken = getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;


  const existingAuthHeader = headers?.Authorization || headers?.authorization;
  headers = {
    Authorization: existingAuthHeader || authHeader,
    // instance: "in",
    // language: "en",
    ...headers
  };

  Object.keys(headers).forEach(k => { if (!headers[k]) delete headers[k] });

  try {
    const reqOptions = {
      method,
      params,
      url: path,
      headers,
      baseURL,
      data: payload
    };
    if (responseType !== undefined) {
      reqOptions.responseType = responseType
    }
    const response = await platform.request(reqOptions);
    return response.data;
  } catch (error) {
    return error?.response?.data || error;
  }
};


// Add a request interceptor
platform.interceptors.request.use(
  function (config) {
    /*     const accessToken = storageManager.getAccessToken();
        const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;
        const existingAuthHeader = config.headers.Authorization || config.headers.authorization;
        config.headers = {
          Authorization:  existingAuthHeader || authHeader,
          ...config.headers
        }; */
    //console.log("-- request>>",config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
platform.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
