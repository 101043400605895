/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, useMemo } from 'react';
import { Table, ImageCard, CustomButton, SectionCard, Modal, CustomIconButton, TableLoader, FormInput, CustomDropdown, Loader } from '../../../components'
import { Link } from 'react-router-dom';
import { makeApiCall } from '../../../utils/makeApiCall';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import Style from './index.module.scss';

const NUMBER_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => ({ label: num, value: num }));
const PLACEMENT_OPTIONS = ["LEFT", "RIGHT", "FRONT", "BACK"].map(p => ({ label: p, value: p }));


const AddBox = ({ onSubmitButtonClick }) => {

    const [name, setName] = useState(null);
    const [selectedPlacement, setSelectedPlacement] = useState();
    const [selectedQty, setSelectedQty] = useState();


    const onSubmitHandle = () => {
        onSubmitButtonClick({ name, placement: selectedPlacement.value, image_count: selectedQty.value });
    }

    return (<div className={Style.add_box_container}>
        <h4 className={Style.form_label}>Add New Room</h4>
        <div className={Style.form_value}>

            <div style={{ width: "25%" }}>
                <FormInput name="name" value={name} onChange={e => setName(e.target.value)} placeholder="Name" fullWidth />
            </div>
            <div style={{ width: "25%" }}>
                <CustomDropdown
                    placeholder="Select Image Count"
                    onChange={(opt) => setSelectedQty(opt)}
                    options={NUMBER_OPTIONS}
                    value={selectedQty}
                />
            </div>

            <div style={{ width: "25%" }}>
                <CustomDropdown
                    placeholder="Select Placement"
                    onChange={(opt) => setSelectedPlacement(opt)}
                    options={PLACEMENT_OPTIONS}
                    value={selectedPlacement}
                />
            </div>
            <div style={{ width: "22%" }}>
                <CustomButton text={'Add'} onClick={onSubmitHandle} />
            </div>
        </div>
    </div>)
};

const Room = ({ room, onRoomUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [roomOptions, setRoomOptions] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [updatedRoom, setUpdatedRoom] = useState(null);
    const [selectedQty, setSelectedQty] = useState(null);
    const [selectedPlacement, setSelectedPlacement] = useState(null);

    useEffect(() => {
        setUpdatedRoom(room);
        setSelectedPlacement({ label: room?.placement, value: room?.placement });
        setSelectedQty({ label: room?.image_count, value: room?.image_count });
    }, [])


    const loadArtistOptions = async (query, callback) => {
        setLoading(true);
        try {
            const res = await makeApiCall({
                method: "GET",
                path: `/users/artist?q=${query}`
            })
            const filteredResult = res?.data?.map(a => ({ label: a.name, value: a.id }));
            return filteredResult;
        } catch (err) {
            console.error(err);
        }
    };

    const onArtistChange = async (option) => {
        if (option != null) {
            setSelectedArtist(option);
            try {
                setLoading(true);
                const res = await makeApiCall({
                    method: "GET",
                    path: `/rooms?artistId=${option?.value}`
                })
                if (res?.data) {
                    const filteredRes = res.data.map(r => ({ ...r, label: r.name, value: r.id }))
                    setRoomOptions(filteredRes);
                }
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        } else {
            setSelectedArtist(null);
        }
    };

    const onArtistRoomChange = (option) => {
        if (option != null) {
            setSelectedRoom(option);
        } else {
            setSelectedRoom(null);
        }
    };

    const formatOptionLabel = (value) => `${value.label}`;

    const onUpdateRoom = () => {
        onRoomUpdate({
            ...room,
            placement: selectedPlacement.value,
            image_count: selectedQty.value,
            artist_room_id: selectedRoom?.id
        })
    };

    return (
        <div className={Style.room_container}>
            <div className={Style.room_header}>
                {room.name}
            </div>
            <div className={Style.room_body}>

                <div style={{ display: "flex", gap: "5px", marginBottom: "10px" }}>
                    <CustomDropdown
                        placeholder="Select"
                        onChange={(opt) => setSelectedQty(opt)}
                        options={NUMBER_OPTIONS}
                        value={selectedQty}
                    />

                    <CustomDropdown
                        placeholder="Select"
                        onChange={(opt) => setSelectedPlacement(opt)}
                        options={PLACEMENT_OPTIONS}
                        value={selectedPlacement}
                    />
                </div>

                {
                    room.artist_room_id ? "You have already assigned this room" :
                        <>
                            <div className={Style.invitation_container}>
                                <span style={{ width: "300px" }}>
                                    <CustomDropdown
                                        type="async"
                                        placeholder="Search Artists"
                                        onChange={onArtistChange}
                                        formatOptionLabel={formatOptionLabel}
                                        loadOptions={loadArtistOptions}
                                        noOptionsMessage="There are no Artists to display"
                                        value={selectedArtist}
                                        isClearable={true}
                                    />
                                </span>
                                <span style={{ width: "300px" }}>
                                    {roomOptions?.length > 0 ? <CustomDropdown
                                        placeholder="Search Room"
                                        onChange={onArtistRoomChange}
                                        options={roomOptions}
                                        value={selectedRoom}
                                    /> : ""}
                                </span>
                            </div>
                            <div>
                                {selectedRoom?.posts?.length > 0 ?
                                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", marginTop: "8px" }}>
                                        {selectedRoom.posts.map(post =>
                                            <div key={post.id} style={{ background: "#eee", padding: "5px", width: "100px" }}>
                                                <img src={post.image} alt={post.name} style={{ maxWidth: "100%" }} />
                                            </div>
                                        )}
                                    </div>
                                    : ""
                                }
                            </div>
                        </>
                }

                <div style={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>
                    <CustomButton text="Add" onClick={onUpdateRoom} style={{ width: "200px" }} />
                </div>

            </div>
        </div>
    )
};

export default () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [fairOptions, setFairOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFair, setSelectedFair] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/fairs/individual"
            })
            const filteredResult = res?.data?.map(r => ({ label: r.name, value: r.id, ...r }));
            setFairOptions(filteredResult);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onUpdateFairClicked = async () => {
        setLoading(true);
        const res = await makeApiCall({
            method: "PUT",
            path: `/fairs/${selectedFair.id}`,
            payload: selectedFair,
        })
        if (res.success) {
            enqueueSnackbar('Successfully Updated', { variant: 'success' });
            setLoading(false);
            setLoading(false);
        } else {
            enqueueSnackbar(res?.error || 'Something went Wrong', { variant: 'error' });
            setLoading(false);
        }
    };

    const onRoomUpdateHandle = (room) => {
        const newRooms = selectedFair.rooms;
        const modifiedRooms = newRooms.map(nr => nr._id === room._id ? nr = room : nr);
        setSelectedFair({
            ...selectedFair,
            rooms: modifiedRooms
        })
    };

    const createRoom = async ({ name, placement, image_count }) => {
        const newFair = { ...selectedFair };
        const newRooms = newFair.rooms;
        newRooms.push({
            name,
            placement,
            image_count
        });
        newFair.rooms = newRooms;

        setLoading(true);
        const res = await makeApiCall({
            method: "PUT",
            path: `/fairs/${newFair.id}`,
            payload: newFair,
        })
        if (res.success) {
            enqueueSnackbar('Room Created Succesfully', { variant: 'success' });
            setLoading(false);
            setLoading(false);
        } else {
            enqueueSnackbar(res?.error || 'Something went Wrong', { variant: 'error' });
            setLoading(false);
        }
    };

    return (
        <div>
            <SectionCard defaultPadding title="Rooms">

                {loading ?
                    <div className="loader_container"><Loader /></div> :

                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "5px" }}>
                        <div style={{ width: '300px', margin: '10px 0' }}>
                            <CustomDropdown options={fairOptions} placeholder="Select Fair" onChange={value => setSelectedFair(value)} value={selectedFair} />
                        </div>
                        <CustomButton text="Update" onClick={onUpdateFairClicked} disabled={!(selectedFair?.value)} style={{ width: "200px" }} />
                    </div>

                }

                {selectedFair?.id ?
                    <>
                        {selectedFair?.rooms.length > 0 && selectedFair?.rooms.map(r =>
                            <div key={r._id} style={{ marginBottom: "10px" }}>
                                <Room room={r} onRoomUpdate={onRoomUpdateHandle} />
                            </div>
                        )}

                        <div className={Style.create_room}>
                            <AddBox buttonText="Create New Room" onSubmitButtonClick={createRoom} placeholder="Type Room Name" />
                        </div>

                    </> : null
                }

            </SectionCard>
        </div>
    )
};