/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, useMemo } from 'react'
import { Table, ImageCard, CustomButton, SectionCard, Modal, CustomIconButton, TableLoader, FormInput, CustomDropdown } from '../../../components'
import { makeApiCall } from '../../../utils/makeApiCall';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2'
import Style from './index.module.scss';


const CategoryForm = ({ type, data, getNewList, closeModal }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const OPTIONS = [
        { label: "Fair Category", value: "fc" },
        { label: "Sponsor Category", value: "sc" },
        { label: "Participate Category", value: "pc" }
    ];
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        name: ""
    });
    const [category, setCategory] = useState({ label: "Fair Category", value: "fc" });

    const inputs = [
        {
            name: "name",
            label: "Name",
            errormessage:
                "Name should be 3-16 characters and shouldn't include any special character!",
            pattern: "^[A-Za-z0-9]{3,16}$",
            required: true,
        }
    ];

    const onChangeHande = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (type === 'add') {
            const res = await makeApiCall({
                method: "POST",
                path: "/categories",
                payload: {
                    type: category.value,
                    name: values.name
                },
            })

            if (res.success) {
                enqueueSnackbar('Added successfully', { variant: 'success' });
                closeModal();
                getNewList();
            } else {
                enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
            }
        }

        if (type === 'edit') {
            const res = await makeApiCall({
                method: "PUT",
                path: `categories/${data._id}`,
                payload: {
                    type: category.value,
                    name: values.name
                },
            })

            if (res.success) {
                enqueueSnackbar('Added successfully', { variant: 'success' });
                closeModal();
                getNewList();
            } else {
                enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        if (type === 'edit') {
            const cat = OPTIONS.filter(o => o.value === data.type);
            setValues({ name: data.name })
            setCategory(cat[0])
        }
    }, []);


    return (
        <form onSubmit={handleSubmit} style={{ minWidth: '300px' }}>
            <CustomDropdown options={OPTIONS} placeholder="Select" onChange={value => setCategory(value)} value={category} />
            {inputs.map((input) => (
                <FormInput
                    {...input}
                    key={input.name}
                    value={values[input.name]}
                    onChange={onChangeHande}
                    disabled={loading}
                    fullWidth
                />
            ))}

            <div className="form_submit_button">
                <CustomButton variant="contained" color="success" size="small" text={type === 'edit' ? 'Update' : 'Add'} type="submit" loading={loading} disabled={loading} />
            </div>
        </form>
    )
};

export default () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false);
    const [addOrganiserDialog, setAddOrganiserDialog] = useState(false);
    const [editOrganiserDialog, setEditOrganiserDialog] = useState(false);
    const [editOrganiserData, setEditOrganiserData] = useState(false);

    const columns = useMemo(() => [
        {
            Header: '#',
            Cell: (row) => <span>{Number(row.row.id) + 1}</span>
        },
        {
            Header: 'Category Name',
            accessor: 'name'
        },
        {
            Header: 'Category Type',
            accessor: 'type'
        },
        {
            Header: 'createdAt',
            accessor: 'createdAt',
            Cell: ({ value }) => `${format(new Date(value), 'dd-MMM-yyyy')}`
        },
        {
            Header: 'updatedAt',
            accessor: 'updatedAt',
            Cell: ({ value }) => `${format(new Date(value), 'dd-MMM-yyyy')}`
        },
        {
            Header: 'Actions',
            accessor: d => d,
            Cell: ({ value }) => <div><CustomIconButton text="delete" onClick={() => onDeleteOrganiserHandle(value._id)} /> <CustomIconButton text="edit" onClick={() => onEditOrganiserHandle(value)} /></div>
        }
    ], []);

    const fetchData = async () => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: "/categories"
            })
            setCategories(res);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    const toggleAddCategoryDialog = () => {
        setAddOrganiserDialog(!addOrganiserDialog);
    };

    const toggleEditCategoryDialog = () => {
        setEditOrganiserDialog(!editOrganiserDialog);
    };

    const onEditOrganiserHandle = (value) => {
        setEditOrganiserData(value);
        toggleEditCategoryDialog();
    };

    const onDeleteOrganiserHandle = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4B0082',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
        if (result.isConfirmed) {
            const res = await makeApiCall({
                method: "DELETE",
                path: `categories/${id}`,
            })
            if (res.success) {
                enqueueSnackbar('Category Deleted Successfully', { variant: 'success' });
                fetchData();
            } else {
                enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
            }
        }
    };

    return (
        <div>
            <SectionCard noPadding title="Manage Categories" addButtonText="Add New Category" onAddButtonClick={toggleAddCategoryDialog}>
                {loading && <TableLoader count={10} />}

                {categories?.data?.length > 0 &&
                    <Table
                        columns={columns}
                        loading={loading}
                        data={categories.data}
                    />
                }
            </SectionCard>


            <Modal title="Add Category" open={addOrganiserDialog} onClose={toggleAddCategoryDialog}>
                <CategoryForm type="add" getNewList={fetchData} closeModal={toggleAddCategoryDialog} />
            </Modal>

            <Modal title="Edit Category" open={editOrganiserDialog} onClose={toggleEditCategoryDialog}>
                <CategoryForm type="edit" data={editOrganiserData} getNewList={fetchData} closeModal={toggleEditCategoryDialog} />
            </Modal>
        </div>
    )
};