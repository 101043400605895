import React, { useEffect, useState } from "react";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { Routes, Route, Link } from "react-router-dom";
import { Layout, PrivateRoute } from "./components";
import './styles.scss';
import { publicRoutes, privateRoutes, filteredRoute } from "./routes";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import { NotFound } from "./pages";

const App = () => {
  const { currentUser } = useAuth();
  const [filteredRoutes, setFilteredRoute] = useState([])

  useEffect(() => {
    if (!currentUser) return;
    const filtered = filteredRoute(currentUser?.role)
    setFilteredRoute(filtered);
  }, [currentUser])


  return (
    <div>
      <Routes>

        {publicRoutes.map(pr =>
          <Route path={pr.path} element={pr.Component} key={pr.id} />
        )}

        <Route element={<Layout />}>
          <Route element={<PrivateRoute />}>
            {filteredRoutes.map(pr =>
              <Route path={pr.path} element={<>{pr.Component}</>} key={pr.id} />
            )}
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>


      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default App;