export const showAleart = (res, text) => {
  if (res.success) {
    return (text, { variant: 'success' });
  } else {
    return (res?.error || 'Something went Wrong', { variant: 'error' });
  }
};

export const API_BASE_URL = process.env.REACT_APP_PROFILE === 'local' ? 'http://localhost:5000' : 'https://hifairs.azurewebsites.net';

export const ROLE = {
  "default": "",
  "super_admin": "Super Admin",
  "admin": "Admin",
  "organiser": "Organiser",
  "ARTIST": "Artist",
  "sponsor": "Sponsor"
};

export const AGE_GROUP_OPTIONS = [
  { label: "3 to 10 Years", value: "3-10" },
  { label: "11 to 17 Years", value: "11-17" },
  { label: "18 to 20 Years", value: "18-20" },
  { label: "21 to 30 Years", value: "21-30" },
  { label: "Women's", value: "women" },
  { label: "Men's", value: "men" }
];

export const FAIR_TYPE_OPTIONS = [
  { label: "2D", value: "2d" },
  { label: "3D", value: "3d" },
  { label: "AR", value: "ar" },
  { label: "VR", value: "vr" }
]

export const htmlDecode = (input) => {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

export const unescapeHTML = (escapedHTML) => {
  return escapedHTML ? escapedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&') : "";
};
