import React, { createContext, useContext, useState, useEffect } from 'react';
import { makeApiCall } from '../utils/makeApiCall';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import { clearStorage, getAccessToken, setAccessToken } from '../utils/storage';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext)
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true)

  const signup = async ({ name, email, password, mobile, role }) => {
    const res = await makeApiCall({
      method: "POST",
      path: '/auth/register',
      payload: {
        name,
        email,
        password,
        mobile, 
        role
      }
    })
    new Promise((resolve, reject) => {
      if (res.success) {
        //redirect to login page
        enqueueSnackbar('Registed successfully, Please Login to enjoy our service', { variant: 'success' });
        resolve()
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        reject()
      }
    })
  };

  const login = async ({ email, password }) => {
    const res = await makeApiCall({
      method: "POST",
      path: '/auth/login',
      payload: {
        email,
        password
      }
    })
    new Promise((resolve, reject) => {
      if (res.success) {
        setAccessToken(res.token);
        getUserData();
        enqueueSnackbar('LoggedIn successfully', { variant: 'success' });
        navigate('/dashboard');
        resolve();
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        navigate('/')
        reject()
      }
    })
  };

  const logout = async () => {
    const res = await makeApiCall({
      method: "GET",
      path: '/auth/logout'
    })

    new Promise((resolve, reject) => {
      if (res.success) {
        enqueueSnackbar('Logged Out successfully', { variant: 'success' });
        clearStorage();
        navigate('/');
        resolve()
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        reject()
      }
    })
  };

  const getUserData = async () => {
    setLoading(true)
    const res = await makeApiCall({
      method: "GET",
      path: '/auth/me',
    })
    if (res.success && res.data.role) {
      setCurrentUser(res.data)
      navigate(location.pathname === '/' ? '/dashboard' : location.pathname)
      setLoading(false)
    } else {
      enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
      logout();
      setLoading(false)
    }
  };

  const updateProfileImage = async (url) => {
    const res = await makeApiCall({
      method: "POST",
      path: '/auth/update-password',
      payload: {
        avatar: url
      }
    })
    if (res.success) {
      getUserData();
      enqueueSnackbar('Profile image updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
      navigate('/')
    }
  };

  const forgotPassword = async ({ email }) => {
    const res = await makeApiCall({
      method: "POST",
      path: '/auth/forgotpassword',
      payload: {
        email
      }
    })
    new Promise((resolve, reject) => {
      if (res.success) {
        //redirect to login page
        enqueueSnackbar('Email Sent successfully', { variant: 'success' });
        resolve()
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        reject()
      }
    })
  };

  const resetPassword = async ({ password, token }) => {
    const res = await makeApiCall({
      method: "PUT",
      path: `/auth/resetpassword/${token}`,
      payload: {
        password
      }
    })

    new Promise((resolve, reject) => {
      if (res.success) {
        enqueueSnackbar('Password reset succesfully', { variant: 'success' });
        clearStorage();
        navigate('/login');
        resolve()
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        reject()
      }
    })
  };

  const updatePassword = async ({ currentPassword, newPassword }) => {
    const res = await makeApiCall({
      method: "PUT",
      path: '/auth/updatepassword',
      payload: {
        currentPassword,
        newPassword
      }
    })
    new Promise((resolve, reject) => {
      if (res.success) {
        enqueueSnackbar('Password Updated successfully', { variant: 'success' });
        resolve()
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        reject()
      }
    })
  };

  const updateUserInfo = async (payload) => {
    const res = await makeApiCall({
      method: "PUT",
      path: '/auth/updateuserinfo',
      payload
    })

    new Promise((resolve, reject) => {
      if (res.success) {
        setCurrentUser(res.data)
        resolve();
        enqueueSnackbar('Succesfully updated', { variant: 'success' });
      } else {
        enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
        reject();
      }
    })
  };

  useEffect(() => {
    setLoading(true);
    const token = getAccessToken() || null
    if (token) {
      getUserData()
    } else {
      setLoading(false);
    }
  }, [])

  const value = {
    currentUser,
    signup,
    login,
    logout,
    forgotPassword,
    resetPassword,
    updatePassword,
    updateProfileImage,
    updateUserInfo
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <p>Loading...</p> : children}
    </AuthContext.Provider>
  )
};