import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Style from './index.module.scss';
import { Card } from '@mui/material';


// colors : 'inherit', 'primary', 'secondary', 'error', 'info', 'success', 'warning'
// size : number
// thickness: number

export const Loader = ({ color = "primary", classes = undefined, size = 40, thickness = 3.6, style = {} }) => {
  return (
    <CircularProgress
      color={color}
      size={size}
      thickness={thickness}
      classes={classes}
      style={style} />
  );
}

export const MenuLoader = ({ count = 1, height = 30 }) => {
  return (
    <Stack spacing={1}>
      {[...Array(count)].map((e, i) => {
        return <Skeleton variant="rectangular" width="100%" height={height} key={i} />
      })}
    </Stack>
  )
}

export const TableLoader = ({ count = 1, height = 40 }) => {
  return (
    <div className={Style.table_loader_container}>
      <Stack spacing={0.3}>
        {[...Array(count)].map((e, i) => {
          return <Skeleton variant="rectangular" width="100%" height={height} key={i} />
        })}
      </Stack>
    </div>
  )
}

export const TableHeaderLoader = ({ count = 1, height = 40 }) => {
  return (
    <div className={Style.table_loader_container}>
      <Stack spacing={1} direction="row">
        {[...Array(count)].map((e, i) => {
          return <Skeleton variant="rectangular" width="100%" height={height} key={i} />
        })}
      </Stack>
    </div>
  )
}


export const CardLoader = ({ count = 1, height = 300 }) => {
  return (
    <div className={Style.card_container}>
      <Stack spacing={3} direction="row">
        {[...Array(count)].map((e, i) => {
          return (
            <Card className={Style.card} key={i}>
              <div>
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
                <br />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
              </div>
              <Skeleton animation="wave" height={10} width="40%" />
              <br />
              <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            </Card>
          )
        })}
      </Stack>
    </div>
  )
}