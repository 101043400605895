import React, { createContext, useContext, useState, useEffect } from 'react';

const ChatContext = createContext();

export const ChatState = () => {
    return useContext(ChatContext)
};

export const ChatProvider = ({ children }) => {

    const [notification, setNotification] = useState([]);


    const value = { notification, setNotification };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    )
};