import React, { useRef, useState } from 'react'
import Style from './index.module.scss';


const NotFound = () => {

  return (
    <div className="main_container">
      <div className={Style.container}>
        <h1>404</h1>
        <p>Oops! Sorry the page could not be found.</p>
      </div>
    </div>
  )
}

export default NotFound
