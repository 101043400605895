/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import SectionCard from '../SectionCard';
import Style from './index.module.scss'
import { useAuth } from '../../contexts/AuthContext';
import { CustomButton } from '../CustomButton';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { ROLE } from '../../utils'



export default () => {

    const { currentUser } = useAuth();

    const getGreet = () => {
        const data = [
            [22, 'Working late'],
            [18, 'Good evening'],
            [12, 'Good afternoon'],
            [5, 'Good morning'],
            [0, 'Whoa, early bird']
        ]
        const hr = new Date().getHours();
        for (var i = 0; i < data.length; i++) {
            if (hr >= data[i][0]) {
                return data[i][1]
                break;
            }
        }
    }

    return (
        <SectionCard minHeight={100} defaultPadding>
            <div className={Style.welcome_card_container}>
                <div className={Style.left}>
                    <h1>Hello {currentUser?.name}, {getGreet()} !!</h1>
                    <p>Welcome to <strong>HiFairs</strong> {ROLE[currentUser?.role]} Dashboard!</p>
                </div>
                <div className={Style.right}>
                    <Link to="/profile">
                        <CustomButton variant="outlined" text="Edit My Profile" startIcon={<EditIcon />} />
                    </Link>
                </div>
            </div>
        </SectionCard>
    )
};