let IN_MEMORY_STORAGE = {};
const TOKEN_LS_KEY = "token";

export const getAccessToken = () => {
  return IN_MEMORY_STORAGE[TOKEN_LS_KEY] ? IN_MEMORY_STORAGE[TOKEN_LS_KEY] : localStorage.getItem(TOKEN_LS_KEY);
}

export const setAccessToken = (data) => {
  IN_MEMORY_STORAGE[TOKEN_LS_KEY] = data;
  localStorage.setItem(TOKEN_LS_KEY, data);
}

export const clearStorage = () => {
  IN_MEMORY_STORAGE = {};
  localStorage.clear();
}