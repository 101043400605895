/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useRef, useState } from "react";
import { CustomButton, CustomIconButton, CustomDropdown, FormInput, Loader, MenuLoader, Modal } from "../";
import { io } from "socket.io-client";
import Style from './index.module.scss';
import { makeApiCall } from '../../utils/makeApiCall';
import { format } from "timeago.js";
import { API_BASE_URL, ROLE } from '../../utils';
import { ChatState } from '../../contexts/ChatContext'
import ScrollableFeed from 'react-scrollable-feed'
import { useSnackbar } from 'notistack';
import { Chip, Avatar, Badge } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';


var socket, selectedChatCompare;

const GroupChatForm = ({ type, data, closeModal, getNewList }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [name, setName] = useState(null);
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const formatOptionLabel = (value) => `${value.label}`;

    useEffect(() => {
        if (type === "edit") {
            setName(data?.name);
            setMembers(data?.users);
        }
    }, [])

    const onSearchUserChange = async (option) => {
        const checkUser = members.some(m => m.id === option.id);
        if (checkUser) {
            enqueueSnackbar('User already added', { variant: 'warning' });
        } else {
            setMembers([...members, option])
        }
    };

    const loadUsersOptions = async (query, callback) => {
        try {
            const res = await makeApiCall({
                method: "GET",
                path: `/users/users?q=${query}`
            })
            const filteredResult = res?.data?.map(a => ({ ...a, label: a.name, value: a.id }));
            return filteredResult;
        } catch (err) {
            console.error(err);
        }
    };

    const onCreateGroup = async () => {
        if (type === "edit") {
            try {
                setLoading(true);
                const res = await makeApiCall({
                    method: "PUT",
                    path: `/chats/group/${data._id}`,
                    payload: {
                        name,
                        users: members.map(m => m.id)
                    }
                })
                if (res.success) {
                    enqueueSnackbar('Updated successfully', { variant: 'success' });
                    closeModal();
                    getNewList();
                } else {
                    enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error(err);
            }
        } else {
            try {
                setLoading(true);
                const res = await makeApiCall({
                    method: "POST",
                    path: `/chats/group`,
                    payload: {
                        name,
                        users: members.map(m => m.id)
                    }
                })
                if (res.success) {
                    enqueueSnackbar('Group Created successfully', { variant: 'success' });
                    closeModal();
                    getNewList();
                } else {
                    enqueueSnackbar(res?.error ? res?.error : 'Something went Wrong', { variant: 'error' });
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error(err);
            }
        }

    };

    const onMemberRemove = (member) => {
        const filteredMembers = members.filter((m) => m.id !== member.id);
        setMembers(filteredMembers);
    };

    return (
        <>
            <div className="form_row">
                <div className="form_label">Group Name : </div>
                <div className="form_value"><FormInput value={name} onChange={e => setName(e.target.value)} /> </div>
            </div>
            <div className="form_row">
                <div className="form_label">Members : </div>
                <div className="form_value">
                    <CustomDropdown
                        type="async"
                        placeholder="Type name of the member to add to the group"
                        onChange={onSearchUserChange}
                        formatOptionLabel={formatOptionLabel}
                        loadOptions={loadUsersOptions}
                        noOptionsMessage="There are no user to display"
                        value={''}
                        isClearable={true}
                    />
                    <div className={Style.member_chip}>
                        {members.length > 0 ? members.map(m =>
                            <Chip
                                avatar={<Avatar alt="Natacha" src={m?.avatar} />}
                                label={`${m?.name} | ${ROLE[m?.role || 'default']}`}
                                onDelete={() => onMemberRemove(m)} />
                        ) : ''}
                    </div>
                </div>
            </div>
            <div>
                <CustomButton text={type === "edit" ? "Update Group" : "Create Group"} onClick={onCreateGroup} />
            </div>
        </>
    )
};

const Message = ({ message, own }) => {
    return (
        <div className={own ? `${Style.message} ${Style.own}` : Style.message}>
            <div className={Style.message_top}>
                {/* <img src="" alt="" /> */}
                <p className={Style.message_text}>{message.content}</p>
            </div>
            <div className={Style.message_bottom}>{format(message.createdAt)}</div>
        </div>
    )
};

export default ({ currentUser }) => {
    const [chats, setChats] = useState([]);
    const [messages, setMessages] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [socketConnected, setSocketConnected] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [chatLoading, setChatLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);
    const [addGroupchatDialog, setAddGroupchatDialog] = useState(false);
    const [editGroupchatDialog, setEditGroupchatDialog] = useState(false);
    const [editGroupData, setEditGroupData] = useState({});
    const { selectedChat, setSelectedChat, user, notification, setNotification } = ChatState();

    const scrollRef = useRef();

    const getChats = async () => {
        setChatLoading(true)
        try {
            const res = await makeApiCall({
                method: "GET",
                path: '/chats'
            })
            setChats(res.data);
            setChatLoading(false)
        } catch (err) {
            console.error(err);
            setChatLoading(false);
        }
    };

    useEffect(() => {
        socket = io(API_BASE_URL);
        socket.emit("setup", currentUser);
        socket.on("connection", () => setSocketConnected(true))
        socket.on("typing", () => setIsTyping(true));
        socket.on("stop typing", () => setIsTyping(false));
    }, []);

    useEffect(() => {
        getChats();
    }, []);

    useEffect(() => {
        selectedChatCompare = currentChat;
    }, [currentChat]);

    useEffect(() => {
        socket.on("message recieved", (newMessageRecieved) => {
            if ((!selectedChatCompare) || (selectedChatCompare?._id !== newMessageRecieved?.chat?._id)) {
                if (!notification.includes(newMessageRecieved)) {
                    setNotification([newMessageRecieved, ...notification]);
                    // setFetchAgain(!fetchAgain);
                }
            } else {
                setMessages([...messages, newMessageRecieved])
            }
        })
    });

    const onTypingHandle = (e) => {
        setNewMessage(e.target.value)
    };

    const onSendMessage = async (e) => {
        e.preventDefault();
        try {
            const res = await makeApiCall({
                method: "POST",
                path: `/messages`,
                payload: {
                    chatId: currentChat._id,
                    content: newMessage
                }
            })
            socket.emit("new message", res.data)
            setMessages([...messages, res.data]);
            setNewMessage("");
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const formatOptionLabel = (value) => `${value.label}`;

    const loadUsersOptions = async (query, callback) => {
        // setLoading(true);
        try {
            const res = await makeApiCall({
                method: "GET",
                path: `/users/users?q=${query}`
            })
            const filteredResult = res?.data?.map(a => ({ ...a, label: a.name, value: a.id }));
            return filteredResult;
        } catch (err) {
            console.error(err);
        }
    };

    const onSearchUserChange = async (option) => {
        if (option != null) {
            if (currentUser.id === option.id) {
                return (console.log("You can not add yourself"))
            } else {
                try {
                    const res = await makeApiCall({
                        method: "POST",
                        path: '/chats',
                        payload: {
                            userId: option.id
                        }
                    });
                    setChats([...chats, res.data]);
                } catch (err) {
                    console.error(err);
                }
            }
        }
    };

    const onSelectChat = async (chat) => {
        setCurrentChat(chat);
        try {
            setMessageLoading(true);
            const res = await makeApiCall({
                method: "GET",
                path: `/messages/${chat._id}`
            });
            setMessageLoading(false);
            setMessages(res.data);
            socket.emit("join chat", chat._id)
        } catch (err) {
            console.error(err);
            setMessageLoading(false);
        }

    };

    const getSender = (loggedinUser, users) => {
        return users?.length > 0 ? users.filter(u => u.id !== loggedinUser.id)[0] : null;
    };

    const toggleAddGroupchatDialog = () => {
        setAddGroupchatDialog(!addGroupchatDialog);
    };

    const toggleEditGroupchatDialog = (group) => {
        setEditGroupData(group);
        setEditGroupchatDialog(!editGroupchatDialog);
    };

    return (
        <>{loading ? <div className="loader_container"><Loader /></div> :
            <div className={Style.container}>
                <div className={Style.chatMenu}>
                    <div className={Style.chatMenuWrapper}>
                        <div style={{ marginBottom: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                            <CustomDropdown
                                type="async"
                                placeholder="Type name to search"
                                onChange={onSearchUserChange}
                                formatOptionLabel={formatOptionLabel}
                                loadOptions={loadUsersOptions}
                                noOptionsMessage="There are no user to display"
                                value={selectedArtist}
                                isClearable={true}
                            />
                            <span style={{ minWidth: '160px' }}>
                                <CustomButton text="Create Group Chat" onClick={toggleAddGroupchatDialog} />
                            </span>
                        </div>
                        {chatLoading ?
                            <div>
                                <MenuLoader count={5} height={50} />
                            </div> :
                            <ScrollableFeed>
                                {chats?.length > 0 && chats.map((c) => (
                                    <div className={c?._id === currentChat?._id ? `${Style.user_list} ${Style.active_list}` : Style.user_list} key={c?._id}>
                                        <div className={Style.chat_container}>
                                            {c.isGroupChat ?
                                                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                                    <div onClick={() => onSelectChat(c)} style={{ display: 'flex', width: '100%', gap: '5px' }}>
                                                        <div className={Style.chat_avatar}> <GroupsIcon /> </div>
                                                        <div className={Style.chat_name}> {c?.name || `Unnammed Group`}</div>
                                                    </div>
                                                    <CustomIconButton text="edit" onClick={() => toggleEditGroupchatDialog(c)} />
                                                </div>
                                                :
                                                <div onClick={() => onSelectChat(c)} style={{ width: '100%' }}>
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                        {/* <Badge color="success" variant="dot" overlap="circular"> */}
                                                        <Avatar alt="Remy Sharp" src={(getSender(currentUser, c.users)?.avatar)} > {getSender(currentUser, c.users)?.name.charAt(0)}</Avatar>
                                                        {/* </Badge> */}
                                                        <div>
                                                            <div className={Style.chat_name}> {(getSender(currentUser, c.users)?.name)} | <span className={Style.role}>{ROLE[(getSender(currentUser, c.users)?.role)]}</span></div>
                                                            <div>
                                                                {c?._id !== currentChat?._id && <div className={Style.last_message}>{c?.latestMessage?.content}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </ScrollableFeed>
                        }
                    </div>
                </div>
                <div className={Style.chatBox}>
                    <div className={Style.chatBoxWrapper}>
                        {currentChat ? (
                            messageLoading ? <div className="loader_container" style={{ height: "100%" }}><Loader /></div> :
                                <>
                                    <div className={Style.chatBoxTop}>
                                        <ScrollableFeed>
                                            {messages.map((m) => (
                                                <div key={m._id}>
                                                    <Message message={m} own={m?.sender?.id === currentUser.id} />
                                                </div>
                                            ))}
                                        </ScrollableFeed>
                                    </div>
                                    <form className={Style.chatBoxBottom} onSubmit={onSendMessage}>
                                        <textarea
                                            className={Style.chatMessageInput}
                                            placeholder="write something..."
                                            onChange={onTypingHandle}
                                            value={newMessage}
                                        ></textarea>
                                        <button className={Style.chatSubmitButton} onClick={onSendMessage}>
                                            Send
                                        </button>
                                    </form>
                                </>
                        ) : (
                            <span className={Style.noConversationText}>
                                Click on a user to start chatting
                            </span>
                        )}
                    </div>
                </div>
            </div>
        }

            <Modal title="Create Group" open={addGroupchatDialog} onClose={toggleAddGroupchatDialog}>
                <GroupChatForm type="add" closeModal={toggleAddGroupchatDialog} getNewList={getChats} />
            </Modal>

            <Modal title="Update Group" open={editGroupchatDialog} onClose={toggleEditGroupchatDialog}>
                <GroupChatForm type="edit" closeModal={toggleEditGroupchatDialog} data={editGroupData} getNewList={getChats} />
            </Modal>
        </>
    );
};