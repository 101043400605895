/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useEffect, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { SectionCard, CounterCard, CustomButton, UserCountCard, TableHeaderLoader, Greetings } from '../../../components';
import Style from './index.module.scss'
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import PageviewIcon from '@mui/icons-material/Pageview';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import { makeApiCall } from '../../../utils/makeApiCall';


export default () => {

  const { currentUser } = useAuth();
  const videoIdref = useRef();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState([{}]);



  const fetchData = async () => {
    // try {
    //   const res = await makeApiCall({
    //     method: "GET",
    //     path: "/admin/count"
    //   })
    //   setCounts(res)
    //   setLoading(false)
    // } catch (err) {
    //   console.error(err);
    // }

    setCounts([
      {
        "total": 10,
        "title": "Test"
      },
      {
        "total": 10,
        "title": "Test"
      },
      {
        "total": 10,
        "title": "Test"
      },
      {
        "total": 10,
        "title": "Test"
      }
    ])
    setLoading(false)
  };

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, []);

  return (
    <div className="dashboard">
     
      <Greetings />

      {loading && <SectionCard minHeight={100} defaultPadding><TableHeaderLoader count={4} height={100} /></SectionCard>}
      {counts.length > 0 && !loading &&
        <div className="group_container gap_2 mb_2">
          {counts.map((count, i) =>
            <UserCountCard count={count.total} title={count.title} icon={<PersonIcon />} />
          )}
        </div>}

      <SectionCard minHeight={300} defaultPadding>
        Hello World
      </SectionCard>

      <div className="group_container gap_2 mb_2">
        <CounterCard count={5154} title="Page views" bgColor="#2673e5" color="#fff" icon={<ScreenSearchDesktopIcon />} />
        <CounterCard count={245} title="User registered" bgColor="#dc3545" color="#fff" icon={<GroupIcon />} />
        <CounterCard count={999} title="Product sales" bgColor="#e1aa04" color="#fff" icon={<ShoppingCartIcon />} />
        <CounterCard count={768} title="Transactions" bgColor="#198754" color="#fff" icon={<AttachMoneyIcon />} />
      </div>


      <SectionCard minHeight={300} defaultPadding>
        More Data will come
      </SectionCard>

    </div>
  )
};